import React from 'react';
import {ReportDamagedOrMissingMedications} from "../component";

export default function ReportDamagedOrMissingMedicationsContent(props: ReportDamagedOrMissingMedications) {
  const [reportDamagedOrMissingMedicationsContentOne, phoneNumber, punctuation,
    reportDamagedOrMissingMedicationsContentTwo] = props.content.split('%');
  return <>
    <pui-heading input={props.title} textSize={"small"} spacingBottom={"small"}/>
    <pui-text input={reportDamagedOrMissingMedicationsContentOne} inline/>
    <pui-link
      id="link-to-report-damaged-or-missing-medications"
      data-csa-c-type="link"
      data-csa-c-action="navigate"
      data-csa-c-content-id="link-to-report-damaged-or-missing-medications"
      data-csa-c-slot-id="report-damaged-or-missing-medications"
      text={`${phoneNumber}${punctuation}`} inline href={`tel:${phoneNumber}`}/>
    <pui-text input={reportDamagedOrMissingMedicationsContentTwo} inline/>
  </>
}