import React from 'react';
import sendClientSideError from './accessors/ClientSideErrorAccessor';

interface Window {
    location: Location;
}

interface Location {
    href: string;
}

declare var window: Window;

export default class ErrorBoundary extends React.Component<any, any> {

    private hasError: any;
    private error: any;
    private errorInfo: any;

    constructor(props: any) {
        super(props);
        this.state = { 
            hasError: false, 
            error: null, 
            errorInfo: null 
        }
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    
    componentDidCatch(error: any, errorInfo: any) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })

        // Log the error to PYO service
        sendClientSideError(window.location.href, error.toString(), errorInfo.componentStack);
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h4>Something went wrong.</h4>
                </div>
            );
        }

        return this.props.children; 
    }
}