import React, {SyntheticEvent} from 'react';
import { Delivery } from "../component";
import { I18NContext } from '../../I18NContext';
import OrderSummary from './OrderSummary';
import ErrorBoundary from '../../ErrorBoundary'

export enum PrimaryActionStyle {
    BUTTON,
    LINK
}

interface DeliveryTrackerProps {
    delivery: Delivery;
    headline: string;
    label: string;
    primaryActionLabel?: string;
    primaryActionStyle?: PrimaryActionStyle;
}

class DeliveryTracker extends React.Component<DeliveryTrackerProps, {}> {

    private bottomSheet: any;

    constructor(props: DeliveryTrackerProps) {
        super(props);
        this.bottomSheet = React.createRef();
    }

    render() {

        const {delivery, headline, label} = this.props;
        const {
            primaryActionLabel = delivery.labels.trackThisDelivery,
            primaryActionStyle = PrimaryActionStyle.BUTTON
        } = this.props;
        const {subline, note, status, events, lastStepProgress} = delivery;

        let theme = 'secondary';
        if (status.status !== "Delivered") {
            theme = 'primary';
            events.push({
                headline: headline,
                subline: delivery.address?.lines.join(', ') || '',
            });
        }

        return (<>
            <ErrorBoundary>
                {this._generatePrimaryAction(primaryActionLabel, primaryActionStyle, theme)}
            </ErrorBoundary>
            
            <pui-bottom-sheet id="see-all-updates-modal" data-csa-c-content-id="see-all-updates-modal" data-csa-c-type="popup" data-csa-c-slot-id="delivery-tracker"
                hideLink ref={this.bottomSheet}>
                <pui-heading input={`${label}`} textSize='large' class='close' />
                <pui-text input={`${subline}`} spacingTop='small' />
                <pui-text input={`${note}`} spacingTop='small' spacingBottom='medium' />
                <ErrorBoundary> <OrderSummary events={events}
                    lastStepProgress={lastStepProgress} /> </ErrorBoundary>
            </pui-bottom-sheet>
        </>
        )
    }

    private _generatePrimaryAction(label: string, style: PrimaryActionStyle, theme: string) {
        const handleOnClick = (e: SyntheticEvent) => {
            e.preventDefault();
            this.bottomSheet.current.show();
        }
        if (style === PrimaryActionStyle.BUTTON) {
            return <pui-button id="button-for-see-all-updates" data-csa-c-type="button" data-csa-c-action="paginate" data-csa-c-content-id="button-for-see-all-updates" data-csa-c-slot-id="delivery-tracker"
                               label={label} onClick={handleOnClick} theme={theme}/>;
        } else {
            return <pui-link id="link-for-see-all-updates" data-csa-c-type="link" data-csa-c-action="paginate" data-csa-c-content-id="link-for-see-all-updates" data-csa-c-slot-id="delivery-tracker"
                                                    textSize="medium" text={label} onClick={handleOnClick}/>;
        }
    }
}

DeliveryTracker.contextType = I18NContext;

export default DeliveryTracker;
