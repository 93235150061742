import {useEffect, useState} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import {getRequestId} from './utils';

export const Marker = {
  AboveTheFold: 'aboveTheFold',
  CriticalFeature: 'criticalFeature',
  Functional: 'functional',
  TimeOfClick: 'tc',
} as const;

export const csa = (name: string): any => {
  const inst = (window as any).csa;
  if (inst) {
    return inst(name);
  }
  return (...arg: any) => {
    if (window.location.hostname === 'localhost') {
      console.debug(`[${Date.now()}] ${arg} (${eventIndex})`);
    }
  };
};

const recordLatencyMarker = (name: string) => {
  if (recordedMarkers.get(name) === eventIndex) {
    return;
  }
  csa('PageTiming')('mark', name);
  recordedMarkers.set(name, eventIndex);
};

const recordNewPage = (params: any) => {
  const spa = csa('SPA');
  const rid = getRequestId();
  const localParams = rid ? {...params, requestId: rid} : params;
  spa('newPage', localParams);
};

let eventIndex = 0;
const recordedMarkers = new Map<string, number>();

export const useCSA = () => {
  const onPageUpdate = () => {
    eventIndex += 1;
    recordLatencyMarker(Marker.TimeOfClick);
  };
  const onNewPage = (params: any = {}) => {
    eventIndex += 1;
    recordNewPage(params);
    recordLatencyMarker(Marker.TimeOfClick);
  };
  const recordMarker = (name: string) => {
    recordLatencyMarker(name);
  };
  return {onPageUpdate, onNewPage, recordMarker};
};

/**
 * Use this hook to automatically track transitions in SPA application when URL changes.
 * If your app uses automatic redirects (not caused by user interactions) this hook might not work properly.
 */
export const useCsaRouteTracker = () => {
  const location = useLocation();
  const history = useHistory();
  const {onNewPage} = useCSA();

  const [currentPage, setCurrentPage] = useState(location.pathname);

  useEffect(() => {
    return history.listen((location) => {
      if (currentPage !== location.pathname) {
        setCurrentPage(location.pathname);
        onNewPage();
      }
    });
  }, [currentPage,history,onNewPage]);
};

export const reset = () => {
  recordedMarkers.clear();
};