import React from 'react';
import { ListDelimiter } from './ListDelimiter';
import PUILink from './PUILink';

interface BreadCrumbProps {
    history: { name: string, url: string }[];
    currentPage: string;
}

class BreadCrumb extends React.Component<BreadCrumbProps, {}> {
    render() {
        return (<pui-stack direction='horizontal' mainAxisArrangement="flex-start">
            <ListDelimiter delimiter={<pui-stack-item><pui-text spacingRight='mini' textSize='small' input='›' spacingLeft='mini' /></pui-stack-item>} >
                {this.props.history.map((page, i) =>
                    <PUILink csaId = {page.name?.replace(' ', '-').toLowerCase() + '-breadcrumb'} key={i + '-breadcrumb'} text={page.name} textSize='small' href={`${page.url}`} replace={true} />
                )}
                <pui-stack-item>
                    <pui-text input={this.props.currentPage} textSize='small' />
                </pui-stack-item>
            </ListDelimiter>
        </pui-stack>)
    }
}

interface DeliveryDetailsBreadCrumbProps {
    yourOrders: string;
    delivery: string;
}

class DeliveryDetailsBreadCrumb extends React.Component<DeliveryDetailsBreadCrumbProps, {}> {
    render() {
        const history = createYourOrderHistory(this.props);

        return (<BreadCrumb history={history} currentPage={this.props.delivery} />)
    }
}

interface TrackerPageBreadCrumbProps {
    yourOrders: string;
    tracking: string;
}

class TrackerPageBreadCrumb extends React.Component<TrackerPageBreadCrumbProps, {}> {
    render() {
        const history = createYourOrderHistory(this.props);

        return (<BreadCrumb history={history} currentPage={this.props.tracking} />)
    }
}

interface InvoiceComponentBreadCrumbProps {
    orderId?: string;
    yourOrders: string;
    delivery: string;
    tracking: string;
    order: string;
    invoice: string;
    isRebillOrder: boolean;
    bypassTracker: boolean;
}

class InvoiceComponentBreadCrumb extends React.Component<InvoiceComponentBreadCrumbProps, {}> {
    render() {
        const history = createYourOrderHistory(this.props);
        addDeliveryorTrackerToHistory(this.props, history);
        addOrderToHistory(this.props, history);
        return (<BreadCrumb history={history} currentPage={this.props.invoice} />)
    }
}

interface OrderDetailBreadCrumbProps {
    orderId?: string;
    yourOrders: string;
    tracking: string;
    delivery: string;
    order: string;
    isRebillOrder: boolean;
    bypassTracker: boolean;
}

class OrderDetailBreadCrumb extends React.Component<OrderDetailBreadCrumbProps, {}> {
    render() {
        const history = createYourOrderHistory(this.props);
        addDeliveryorTrackerToHistory(this.props, history);

        return (<BreadCrumb history={history} currentPage={this.props.order} />)
    }
}


function createYourOrderHistory(props: any) {
    return [{ url: '/', name: props.yourOrders }];
}

function addDeliveryorTrackerToHistory(props: any, history: any) {
    // rebill orders will have breadCrumb as Orders > Order > Invoice
    // normal orders will have breadCrumb as Orders > Delivery > Order > Invoice
    // To-do: add delivery id to breadcrumb https://sim.amazon.com/issues/V927412649 !props.bypassTracker
    if(!props.bypassTracker) {
        history.push({ url: '/progress-tracker', name: props.tracking })
    }
}

function addOrderToHistory(props: any, history: any) {
    const urlOrderId = props?.orderId || '';
    history.push({ url: `/order/${urlOrderId}`, name: props.order });
}

class BreadCrumbLoading extends React.Component {
    render() {
        return (<div className="pui-loading-text-small-dark mini-margin-top mini-margin-bottom"/>)
    }
}

export {
    DeliveryDetailsBreadCrumb,
    InvoiceComponentBreadCrumb,
    OrderDetailBreadCrumb,
    BreadCrumbLoading,
    TrackerPageBreadCrumb
}
