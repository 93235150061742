import * as KatalMetrics from '@amzn/katal-metrics';
import { MetricsDriverService } from './MetricsDriverService';


class MetricsService {
    metricsPublisher: KatalMetrics.Publisher;

    constructor(public readonly driverService: MetricsDriverService) {
        const driver = driverService.driver;

        const metricsFailureHandler = (err: any) => {
            console.log('Metrics failure:');
            console.log(err);
        };

        this.metricsPublisher = new KatalMetrics.Publisher(
            driver,
            metricsFailureHandler,
            new KatalMetrics.Context.Builder()
                .withSite('Pharmacy')
                .withServiceName('PharmacyYourOrdersUI')
                .build()
        );
    }

    getMetricsPublisher(): KatalMetrics.Publisher {
        return this.metricsPublisher;
    }

    getMetricsPublisherForMethod(method: string): KatalMetrics.Publisher {
        return this.getMetricsPublisher().newChildActionPublisher(
            new KatalMetrics.Context.Builder().withMethodName(method).build()
        );
    }
}

const appMetrics = new MetricsService(new MetricsDriverService());

export default appMetrics;