import React from 'react';
// import { Delivery, ItemGroupSummary, DeliveryStatusType } from '../component';
import HeavyDivider from '../common/HeavyDivider';
import { I18NContext } from '../../I18NContext';
import { DeliveryDetailsBreadCrumb } from '../common/BreadCrumb';
import DeliveryTracker from './DeliveryTracker';
import { ForwardLink } from '../common/ForwardLink';
import { ListDelimiter } from '../common/ListDelimiter';
import  MedicationCardAutoRefillLine  from '../common/MedicationCardAutoRefillLine';
import { ItemGroupSummary, Delivery, PurchaseHistory } from '../component';
import ItemMedicationSummaryCard from '../common/ItemMedicationSummaryCard';
import PUILink from '../common/PUILink';
import ErrorBoundary from '../../ErrorBoundary';
import Questions from '../common/Questions';

interface DeliveryDetailsProps {
    itemGroupSummary: ItemGroupSummary;
    delivery?: Delivery;
    purchaseHistory: PurchaseHistory;
}

class DeliveryDetails extends React.Component<DeliveryDetailsProps, {}> {

    render() {
        const { delivery, itemGroupSummary, purchaseHistory } = this.props;

        const button = delivery && "NotShipped" !== delivery.status.status && "None" !== delivery.status.status
            ? <DeliveryTracker headline={itemGroupSummary.headline} label={delivery?.label || itemGroupSummary.note} delivery={delivery} />
            : <></>;

        return (
            <pui-stack>
                <DeliveryDetailsBreadCrumb 
                    yourOrders={purchaseHistory.labels.yourOrders} 
                    delivery={purchaseHistory.labels.delivery} 
                />
                <pui-heading input={itemGroupSummary.headline} textSize="extra-large" spacingTop='small' key={"heading-order-detail"} />
                <pui-text input={delivery?.label || itemGroupSummary.note} spacingBottom='small' />
                {button}
                <HeavyDivider />
                <pui-heading input={purchaseHistory.labels.itemsInThisDelivery} textSize='medium' spacingBottom='small' />
                {this._generateItemDetailList()}
                {itemGroupSummary?.address &&
                    <pui-stack>
                        <HeavyDivider />
                        <pui-heading input={purchaseHistory.labels.deliveryAddress} text='large' spacingBottom='medium' />
                        <pui-stack direction='horizontal' secondaryAxisArrangement="center">
                            <pui-stack direction='vertical' width="100%">
                                {itemGroupSummary.address?.lines.map(value => <pui-text key={value} input={value} spacingRight='small' spacingLeft='medium' />)}
                            </pui-stack>
                        </pui-stack>
                    </pui-stack>}
                <HeavyDivider />
                <Questions heading={purchaseHistory.labels.questions} contactCustomerCare={purchaseHistory.labels.contactCustomerCare}/>
            </pui-stack>
        )
    }

    _generateItemDetailList() {
        return <ListDelimiter delimiter={<pui-divider spacingTop='small' spacingBottom='medium' />}>
            {this.props.itemGroupSummary.itemGroups.map((group, i) =>
                <React.Fragment key={group.id}>{group.itemSummaries.map((summary, j) =>
                    (<React.Fragment key={summary.orderId + summary.orderItemId}>
                        {this._buildOrderIDHeader(summary.orderId, group.id)}
                        <ErrorBoundary>
                        <ForwardLink url={`/order/${group.id}`} csaId='order'>
                            <ItemMedicationSummaryCard purchaseItemSummary={summary} 
                            autoRefillCardLine= {<MedicationCardAutoRefillLine isAutoRefill={summary.isAutoRefill} autoRefillTextDisplay={this.props.itemGroupSummary.orderingChannelLine}>
                                                 </MedicationCardAutoRefillLine>}>
                            </ItemMedicationSummaryCard>
                        </ForwardLink>
                        </ErrorBoundary>
                    </React.Fragment>)
                )}</React.Fragment>
            )}
        </ListDelimiter>
    }

    _buildOrderIDHeader(orderId: string, id: string) {
        return (
            <ErrorBoundary>
            <pui-stack direction='horizontal' mainAxisArrangement="start" secondaryAxisArrangement="center">
                <pui-text input={this.props.purchaseHistory.labels.orderId + "&nbsp;"} />
                <PUILink csaId='order' text={orderId} href={`/order/${id}`} />
            </pui-stack>
            </ErrorBoundary>
        )
    }
}

DeliveryDetails.contextType = I18NContext;

export default DeliveryDetails;
