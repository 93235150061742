import React, { FunctionComponent, ReactNode } from 'react';

interface ListDelimiterProps {
    delimiter: JSX.Element;
}

/**
 * ListDelimiter, can be used to create delimiter between components
 * @param props
 * @constructor
 */
export const ListDelimiter: FunctionComponent<ListDelimiterProps> = (props) => {

    let first = true;
    const newArr: ReactNode[] = [];
    let count: number = 1;
    for ( let child of React.Children.toArray(props.children)) {
        if (!first) {
            newArr.push(React.cloneElement(props.delimiter, { key: count++ + '-delimiter'}))
        }
        newArr.push(child);
        first = false;
    }

    return (
        <> {newArr}
        </>
    );
}
