import React from 'react';
import { I18NContext } from '../../I18NContext';
import { BreadCrumbLoading } from '../common/BreadCrumb';
import HeavyDivider from '../common/HeavyDivider';
import MedicationCardLoading from '../common/MedicationCardLoading';

class DeliveryDetailsLoading extends React.Component {
    render() {

        return (
            <pui-stack>
                <BreadCrumbLoading/>
                <div className="pui-loading-heading-extra-large-dark small-margin-top" style={{width: '75%'}}/>
                <div className="pui-loading-text-medium mini-margin-top small-margin-bottom"/>
                <HeavyDivider/>
                <div className="pui-loading-heading-medium-dark small-margin-bottom" />
                <div className="pui-loading-text-large small-margin-bottom small-margin-top" style={{width: '50%'}}/>
                <MedicationCardLoading/>
                <HeavyDivider/>
                <div className="pui-loading-heading-medium-dark small-margin-bottom"/>
                <div className="pui-loading-text-large medium-margin-left small-margin-right medium-margin-left mini-margin-bottom"/>
                <div className="pui-loading-text-large medium-margin-left small-margin-right medium-margin-left mini-margin-bottom"/>
                <div className="pui-loading-text-large medium-margin-left small-margin-right medium-margin-left mini-margin-bottom"/>
                <div className="pui-loading-text-large medium-margin-left small-margin-right medium-margin-left mini-margin-bottom"/>
                <HeavyDivider/>
                <div className="pui-loading-heading-large-dark medium-margin-bottom"/>
                <div className="pui-loading-text-large medium-margin-left small-margin-right medium-margin-left mini-margin-bottom"/>
            </pui-stack>
        )
    }
}

DeliveryDetailsLoading.contextType = I18NContext;

export default DeliveryDetailsLoading;

