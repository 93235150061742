import { Transaction } from './component'

class Map<TValue> {

    store: any = {};

    get(key: string): TValue | null {
        if (this.contains(key)) {
            return this.store[key];
        }
        return null
    }

    set(key: string, value: TValue) {
        this.store[key] = value;
    }

    remove(key: string) {
        delete this.store[key];
    }

    contains(key: string): boolean {
        return this.store.hasOwnProperty(key);
    }

    values(): TValue[] {
        // importantly, these are the value of props that would return true
        // for hasOwnProperty(key)
        return Object.values(this.store);
    }
}

export class TransactionFilters {
    static DistinctCards(transactions:Transaction[]): Transaction[] {
        let map: Map<Transaction> = new Map<Transaction>();
        transactions.forEach(t => {
            // as long as we have a label, we wanna look at displaying it.
            let key = `${t.brand}${t.tail}`
            if (!map.contains(key)) {
                map.set(key, t);
            } 
        })

        return map.values();
    }

    static DistinctLabels(transactions:Transaction[]): Transaction[] {
        let map: Map<Transaction> = new Map<Transaction>();
        transactions.forEach(t => {
            // as long as we have a label, we wanna look at displaying it.
            if (!!t.label) {
                let key = `${t.label}${t.brand}${t.tail}`
                if (!map.contains(key)) {
                    map.set(key, t);
                } 
            }
        })

        return map.values();
    }
}
