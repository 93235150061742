import React, { Component } from 'react';
import { I18NContext } from '../../I18NContext';

interface ChargeLineProps {
    chargeAmount: string;
    insuranceLine?: string;
}

class MedicationCardChargeLine extends Component<ChargeLineProps, {}> {
    render() {

        const { insuranceLine, chargeAmount } = this.props;

        return (
            <pui-stack-item overflow="true">
                <pui-stack
                    direction="horizontal"
                    mainAxisArrangement="start"
                    secondaryaxisarrangement="start"
                    spacingBottom="small"
                    >
                    <pui-stack-item>
                        <pui-text
                            input={chargeAmount}
                            text={chargeAmount}
                            textSize="extra-large"
                            spacingRight="mini"
                        />
                    </pui-stack-item>
                    {insuranceLine &&
                        <pui-stack-item overflow="true">
                            <pui-text
                                input={insuranceLine}
                                textSize="small"
                            />
                        </pui-stack-item>}
                </pui-stack>
            </pui-stack-item>
        );
    }
}

MedicationCardChargeLine.contextType = I18NContext;

export default MedicationCardChargeLine;