import React, { useState, Fragment } from 'react';
import {DispenseStatementFilterOption, DispenseStatementPageLabels} from "../component";

interface DispenseStatementFilterOptionsProps {
    labels: DispenseStatementPageLabels;
    filterOptions: DispenseStatementFilterOption[];
    onDownloadClick(filterOption:string | undefined): void;
    alertMessage?: string;
    hideFilterOption?: string;
    showChooseYear?: boolean;
}

function DispenseStatementFilterOptions(props: DispenseStatementFilterOptionsProps) {
    const [currentFilterValue, setCurrentFilterValue] = useState(null);

    const handleRadioClick = (e: React.MouseEvent<any>) => {
        e.preventDefault();
        setCurrentFilterValue(e.currentTarget.value);
    }

    const handleDownloadClick = () => {
        props.onDownloadClick(currentFilterValue || undefined);
    }

    const renderAlert = () => {
        return(
            <>
                <pui-box theme="error" style={{width: "auto"}} spacingTop="small">
                    <pui-stack direction="horizontal">
                        <pui-stack-item>
                            <pui-icon imgClass="status-error-icon" spacingRight="small"/>
                        </pui-stack-item>
                        <pui-stack-item overflow="true">
                            <pui-text input={props.alertMessage}/>
                        </pui-stack-item>
                    </pui-stack>
                </pui-box>
                <pui-divider spacingBottom="small" spacingTop="small" />
            </>
        );
    }

    const renderFilterOption = (filterOption: DispenseStatementFilterOption) => {
        if (filterOption.value === props.hideFilterOption) return;
        return(
            <Fragment key={filterOption.value}>
                <pui-radio-button
                    name="filterOption"
                    value={filterOption.value}
                    label={filterOption.label}
                    onClick={handleRadioClick}
                    checked={currentFilterValue === filterOption.value || undefined}
                />
                <pui-divider spacingBottom="small" spacingTop="small" />
            </Fragment>
        );
    }

    const renderFilterOptions = () => {
        return props.filterOptions.map((option) => renderFilterOption(option));
    }

    return (
        <pui-section flowdirection="vertical" style={{minHeight: "300px"}}>
            <pui-section-column>
                {props.showChooseYear &&<pui-text spacingBottom="medium" input={props.labels.chooseYear}/>}
                {!props.alertMessage && <pui-text spacingBottom="medium" input={props.labels.info}/>}
            </pui-section-column>
            <pui-section-column>
                {props.alertMessage && renderAlert()}
                {renderFilterOptions()}
            </pui-section-column>
            <pui-section-column>
                <pui-button
                    id="button-to-download-dispense-statement"
                    data-csa-c-type="button"
                    data-csa-c-action="download"
                    data-csa-c-content-id="button-to-download-dispense-statement"
                    data-csa-c-slot-id="dispense-statement"               
                    label={props.labels.download}
                    spacingTop="small"
                    textColor="black-color"
                    textSize="extra-large"
                    disabled={currentFilterValue === null || undefined}
                    onClick={handleDownloadClick}
                />
            </pui-section-column>
        </pui-section>
    );
}

export default DispenseStatementFilterOptions;