export default async function sendClientSideError(url: string, error: string, errorInfo: string) {  
  
    const response = await fetch('/your-orders-api/command/clientSideError', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({url, error, errorInfo })
    })

    return response;
}