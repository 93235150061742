import React from 'react';
import { I18NContext } from '../../I18NContext';

interface Window {
    location: Location;
}

interface Location {
    replace(value: string): void;
    origin: string;
}

declare var window: Window;

interface OrderHistoryHeaderProps {
    headline: string;
    helpLink: string;
}

export class OrderHistoryHeader extends React.Component<OrderHistoryHeaderProps, {}> {
    render() {
        return (
            <pui-stack direction="horizontal">
                <pui-stack-item>
                    <pui-stack direction="horizontal">
                        <pui-icon imgClass="dashboard-default-shipping-icon" spacingRight="small" />
                        <pui-heading input={this.props.headline} textSize="extra-large" />
                    </pui-stack>
                </pui-stack-item>
                    
                <pui-stack-item>
                    <pui-stack>
                        <pui-link id="link-for-help" data-csa-c-type="link" data-csa-c-action="navigate" data-csa-c-content-id="link-for-help"
                                  data-csa-c-slot-id="order-history" text={this.props.helpLink} href={window.location.origin + "/help/"} />
                    </pui-stack>
                </pui-stack-item>
            </pui-stack>
        )
    }
}
OrderHistoryHeader.contextType = I18NContext;

export default OrderHistoryHeader;