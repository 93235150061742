import React from 'react';

class InvoiceChargesLoading extends React.Component {
    render() {

        return (<>
            <tr>
                <td><div className="pui-loading-text-medium small-margin-top"/></td>
                <td><div className="pui-loading-text-medium small-margin-top"/></td>
            </tr>
            <tr>
                <td><div className="pui-loading-text-medium"/></td>
                <td><div className="pui-loading-text-medium"/></td>
            </tr>
            <tr>
                <td><div className="pui-loading-text-medium"/></td>
                <td><div className="pui-loading-text-medium"/></td>
            </tr>
            <tr>
                <td><div className="pui-loading-text-medium"/></td>
                <td><div className="pui-loading-text-medium"/></td>
            </tr>
            <tr>
                <td><div className="pui-loading-text-medium"/></td>
                <td><div className="pui-loading-text-medium"/></td>
            </tr>
            <tr>
                <td><div className="pui-loading-text-medium"/></td>
                <td><div className="pui-loading-text-medium"/></td>
            </tr>
            </>
        );
    }
}

export default InvoiceChargesLoading;