import { Transaction } from "../component";

export const isPaymentFixUp = (transactions: Transaction[]): boolean => {
    let paymentFixUp = false;

    if (transactions && transactions.length > 0) {
        transactions.forEach(transaction => {
            transaction?.annotations.forEach(annotation => {
                if (annotation.annotationType === "PayFixup") {
                    paymentFixUp = true;
                }
            });
        });
    }
    return paymentFixUp;
}

export const isBackupPaymentMethodUsed = (transaction: Transaction): boolean => {
    return transaction.originalPaymentMethod != null;
}