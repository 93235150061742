import React, { useEffect, useState } from 'react';
import { I18NContext } from './I18NContext';
import I18N from './Strings';
import PuiContainer from "./components/Container";

import { Switch, Route, HashRouter, useLocation } from "react-router-dom";
import OrderHistoryHOC from './components/order/OrderHistoryHOC';
import DeliveryDetailsHOC from './components/delivery/DeliveryDetailsHOC';
import TrackerHOC from './components/tracker/TrackerHOC';
import OrderDetailHOC from './components/order/OrderDetailHOC';
import InvoiceComponentHOC from './components/invoice/InvoiceComponentHOC';
import ErrorBoundary from './ErrorBoundary';
import { AppContext } from './AppContext';
import AppContextClass from './AppContextClass';
import OrderHistoryLoading from './components/order/OrderHistoryLoading';
import OrderDetailLoading from './components/order/OrderDetailLoading';
import InvoiceComponentLoading from './components/invoice/InvoiceComponentLoading';
import DeliveryDetailsLoading from './components/delivery/DeliveryDetailsLoading';
import {CsaNewPageTransition} from "./components/csa/markers";

const appContextClass = new AppContextClass();
const i8nContextClass = new I18N();

export default () => {

  const [isBusy, setBusy] = useState(true);


  useEffect(() => {
    const fetchFeatureFlags = async () => {
      await appContextClass.pullFeatureFlags();
      setBusy(false);
    };
    fetchFeatureFlags();
  }, []);

  function ScrollToTop() {
      const { pathname } = useLocation();
      useEffect(() => {
          window.scrollTo(0, 0);
      }, [pathname]);
      return null;
  }

  if (isBusy) {
    return (
    <div className="App">
        <pui-sub-nav isProfileReadOnly></pui-sub-nav>
        <HashRouter >
          <ScrollToTop />
          <AppContext.Provider value={appContextClass}>
            <I18NContext.Provider value={i8nContextClass}>
              <PuiContainer>
                  <Switch>
                  <Route exact path="/order/:orderId" render={() => <ErrorBoundary> <OrderDetailLoading/> </ErrorBoundary>} />
                  <Route exact path="/invoice/:invoiceId" render={() => <ErrorBoundary> <InvoiceComponentLoading /> </ErrorBoundary>} />
                  <Route path="/delivery/:deliveryId?" render={() => <ErrorBoundary> <DeliveryDetailsLoading/> </ErrorBoundary>} />
                  <Route path="/progress-tracker/:trackingId?" render={() => <ErrorBoundary> <DeliveryDetailsLoading/> </ErrorBoundary>} />
                  <Route path="/" render={() => <ErrorBoundary> <OrderHistoryLoading/> </ErrorBoundary>} />
                  </Switch>
              </PuiContainer>
            </I18NContext.Provider>
          </AppContext.Provider>
        </HashRouter>
    </div>
    );
  }

  return (
    <div className="App">
        <pui-sub-nav isProfileReadOnly></pui-sub-nav>
        <HashRouter >
          <ScrollToTop />
          <CsaNewPageTransition/>
          <AppContext.Provider value={appContextClass}>
            <I18NContext.Provider value={i8nContextClass}>
              <PuiContainer>
                  <Switch>
                  <Route exact path="/order/:orderId" render={(props) => <ErrorBoundary> <OrderDetailHOC {...props} /> </ErrorBoundary>} />
                  <Route exact path="/invoice/:invoiceId" render={(props) => <ErrorBoundary> <InvoiceComponentHOC {...props} /> </ErrorBoundary>} />
                  <Route path="/delivery/:deliveryId?" render={(props) => <ErrorBoundary> <DeliveryDetailsHOC checkDelivery={true} {...props} /> </ErrorBoundary>} />
                  <Route path="/progress-tracker/:trackingId?" render={(props) => <ErrorBoundary> <TrackerHOC checkDelivery={true} {...props} /> </ErrorBoundary>} />
                  <Route path="/" render={(props) => <ErrorBoundary> <OrderHistoryHOC {...props} /> </ErrorBoundary>} />
                  </Switch>
              </PuiContainer>
            </I18NContext.Provider>
          </AppContext.Provider>
        </HashRouter>
    </div>
  );
}