import React from 'react';
import {Annotation, ItemGroup, ItemGroupSummary} from "../component";
import {ForwardTextLink} from "./ForwardTextLink";
import ItemMedicationSummaryCard from "./ItemMedicationSummaryCard";
import MedicationCardAutoRefillLine from "./MedicationCardAutoRefillLine";
import ItemAnnotations from "./ItemAnnotations";
import {CardStyle} from "./MedicationCard";

interface ItemGroupCardProps {
    itemGroupSummary: ItemGroupSummary;
    trackingLinkLabel: string;
    orderDetailsLinkLabel: string;
    bypassTracker: boolean;
}

export default function ItemGroupCard({itemGroupSummary, trackingLinkLabel, orderDetailsLinkLabel,
                                                         bypassTracker}: ItemGroupCardProps) {
    let csaId: string;
    let nextPageUrl: string;
    let nextPageLabel: string;
    if (bypassTracker) {
        csaId = 'order';
        nextPageUrl = `/order/${itemGroupSummary.itemGroups[0].id}`;
        nextPageLabel = orderDetailsLinkLabel;
    } else {
        csaId = 'progress-tracker';
        nextPageUrl = `/progress-tracker/${itemGroupSummary.id}`;
        nextPageLabel = trackingLinkLabel;
    }

    const generateHeader = () => {
        return (
            <pui-stack direction="horizontal" secondaryAxisArrangement="start">
                <pui-heading input={itemGroupSummary.headlineAbbr} headingLevel="3" textSize="small"/>
                <pui-link id={`link-for-ingress-into-${csaId}`} data-csa-c-type="link" data-csa-c-action="navigate" data-csa-c-content-id={`link-for-ingress-into-${csaId}`} data-csa-c-slot-id="order-history"
                        text={nextPageLabel} href={`#${nextPageUrl}`} style={{whiteSpace: "nowrap"}} textSize="medium" />
            </pui-stack>
        );
    }

    const ignoredAnnotationsForSummaries = new Set<string>(["UpdatePaymentMethod", "Cancel", "ItemCancelledOn"]);
    const ignoredAnnotationsForGroups = new Set<string>(["UpdatePaymentMethod", "Cancel", "ItemCancelledOn", "PayFixup"]);
    const filterAnnotations = (annotations: Annotation[], ignoredAnnotations: Set<string>) => {
        return annotations.filter(annotation => !ignoredAnnotations.has(annotation.annotationType));
    }

    const generateItemGroup = (itemGroup: ItemGroup) => {
        return itemGroup.itemSummaries.map((itemSummary, i) => {
            const RefillCardLine = <MedicationCardAutoRefillLine
                isAutoRefill={itemSummary.isAutoRefill}
                autoRefillTextDisplay={itemGroupSummary.orderingChannelLine}/>;

            itemSummary.annotations = filterAnnotations(itemSummary.annotations, ignoredAnnotationsForSummaries);

            return  <ItemMedicationSummaryCard
                purchaseItemSummary={itemSummary}
                autoRefillCardLine={RefillCardLine}
                showSupply={false}
                cardStyle={CardStyle.HEADLINE_MED_50PX_IMG}
                key={i + '-orderhistorymedcard'}/>;
        });
    }

    return(
        <pui-product-card noshadow="true" key={itemGroupSummary.id} spacingBottom="med-small" paddingTop="med-small" paddingLeft="mini" paddingRight="med-small">
        <ForwardTextLink url={nextPageUrl} >
            {itemGroupSummary.headline && generateHeader()}
            {itemGroupSummary.itemGroups.map((itemGroup) => <React.Fragment key={itemGroup.id}>
                    {generateItemGroup(itemGroup)}
                    {(itemGroup.annotations.length > 0) && <pui-stack spacingLeft="large" direction="horizontal">
                        <ItemAnnotations annotations={filterAnnotations(itemGroup.annotations, ignoredAnnotationsForGroups)} textSize="small" textColor="black"/>
                    </pui-stack>}
                </React.Fragment>
            )}
        </ForwardTextLink>
    </pui-product-card>);
}
