import React from 'react';
import { I18NContext } from '../../I18NContext';
import { Item, Order } from '../component';
import InvoiceCharges from './InvoiceCharges';
import InvoiceAttributeRow from './InvoiceAttributeRow';
import { formatMonDDYYY } from '../common/FormatUtils';
import ErrorBoundary from '../../ErrorBoundary';
import { getLocale } from '../../Utils';
import RebillOrderType from '../../common/Types';


interface InvoiceItemSummaryTagProps {
    item: Item;
    order: Order;
}

class InvoiceItemSummaryTag extends React.Component<InvoiceItemSummaryTagProps, {}> {
    render() {
        return (
            <pui-stack>
                <pui-heading input={this.props.item.rx.headline} />
                <pui-text input={this.props.item.rx.subline} />
                {this.props.item.hbaEligibility.hbaEligible === "Eligible" && <pui-text input={this.props.order.charges.hbaEligibleTotalLabel} />}
                <table style={{ width: '100%' }}>
                    <tbody>
                        {this.props.item.drugType !== "OTC" && <InvoiceAttributeRow label={this.props.order.invoicePageLabels.ndcId} value={this.props.item.rx?.ndc11}/>}
                        <InvoiceAttributeRow label={this.props.order.invoicePageLabels.itemQuantity} value={this.props.item.rx?.quantity?.number} />
                        <InvoiceAttributeRow label={this.props.order.invoicePageLabels.unitPrice} value={this.props.item.charges.unitPrice} />
                        <InvoiceAttributeRow label={this.props.order.invoicePageLabels.patient} value={this.props.item.patient?.name} />
                        <InvoiceAttributeRow label={this.props.order.invoicePageLabels.prescriptionId} value={this.props.item.rx?.prescriptionNumber} />
                        <InvoiceAttributeRow label={this.props.order.invoicePageLabels.prescriber} value={this.props.item.rx?.prescriber} />
                        <InvoiceAttributeRow label={this.props.order.invoicePageLabels.filledOn} value={formatMonDDYYY(this.props.item.rx?.filledOn?.value, getLocale())} />
                        {
                            // Insurance/other card information should be omitted for Arrow.
                            this.props.item.insurances && this.props.item.insurances.some(insurance => {
                                return insurance.cardKind.type === "Arrow"
                            }) ? <></> : <InvoiceAttributeRow label={this.props.order.invoicePageLabels.insurance} values={this.props.item.insurances?.map(ins => ins.tailLabel!)} />
                        }
                        {this.props.item.soldBy?.address?.lines?.length && <ErrorBoundary> <InvoiceAttributeRow label={this.props.order.invoicePageLabels.soldBy} values={this.props.item.soldBy.address.lines} /> </ErrorBoundary>}
                        <InvoiceAttributeRow label={this.props.order.invoicePageLabels.nabpNpi} value={this.props.item.rx?.nabp} />
                        {this._generateShipSpeedInformation()}
                        <InvoiceCharges {...this.props.item.charges} />
                    </tbody>
                </table>
            </pui-stack>
        )
    }

    
    _generateShipSpeedInformation() {
        const { order } = this.props;
        const isRebillOrder = order.reverseRebillOrderDetails?.rebillOrderType === RebillOrderType.REBILL_ORDER;
        if (isRebillOrder) {
           return <></>
        } 
        return (<InvoiceAttributeRow label={this.props.order.invoicePageLabels.shippingDate} value={ formatMonDDYYY(this.props.item.shipmentDate?.value, getLocale())} />);
       
    }
}

InvoiceItemSummaryTag.contextType = I18NContext;

export default InvoiceItemSummaryTag;