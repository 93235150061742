import React from 'react';

import './OrderDetail.scss';
import { I18NContext } from '../../I18NContext';
import Help from '../Help';
import { Order } from '../component';
import MedicationCardChargeLine from '../common/MedicationCardChargeLine';
import Charges from '../Charges';
import HeavyDivider from '../common/HeavyDivider';
import { ListDelimiter } from '../common/ListDelimiter';
import Cancel from '../common/Cancel';
import Payment from '../common/Payment';
import ExternalLink from '../common/ExternalLink';
import ItemMedicationSummaryCard from '../common/ItemMedicationSummaryCard';
import { OrderDetailBreadCrumb } from '../common/BreadCrumb';
import RefundRow from './RefundRow';
import MedicationCardAutoRefillLine from '../common/MedicationCardAutoRefillLine';
import {checkRebillOrder} from '../../Utils';
import MedicationCardPromotionLine from "../common/MedicationCardPromotionLine";
import { isPaymentFixUp } from '../common/PaymentUtils';
import { PaymentDeclineAlert } from '../common/PaymentDeclineAlert';
import {AboveTheFoldMarker, FunctionalMarker} from "../csa/markers";

interface OrderDetailProps {
    order: Order;
}

class OrderDetail extends React.Component<OrderDetailProps, {}>  {
  render() {
        const { order } = this.props;

        const canCancel = order.items.filter((item) => item.annotations.filter((annotation) => annotation.annotationType === "Cancel").length > 0).length > 0

        const[firstItem] = order.items;

        const suppressPayFixup = firstItem.annotations.some(annotation => annotation.annotationType === "SuppressPayFixup")

        const isRebillOrder = checkRebillOrder(order);

        const showPaymentDeclineAlert = isPaymentFixUp(order.transactions)
            && order.orderDetailPageLabels.paymentLabels.paymentDeclineAlertTitle
            && order.orderDetailPageLabels.paymentLabels.paymentDeclineAlertText;
        return (
            <pui-stack data-csa-c-content-id="order-detail-page" data-csa-c-type="card" data-csa-c-slot-id="order-detail">
                    {showPaymentDeclineAlert &&
                        <PaymentDeclineAlert gqlOrderId={order.id} paymentLabels={order.orderDetailPageLabels.paymentLabels}></PaymentDeclineAlert>}
                    <OrderDetailBreadCrumb 
                        yourOrders={order.orderDetailPageLabels.yourOrders}
                        delivery={order.orderDetailPageLabels.delivery}
                        tracking={order.orderDetailPageLabels.tracking}
                        order={order.orderDetailPageLabels.order}
                        isRebillOrder={isRebillOrder}
                        bypassTracker={order.bypassTracker}
                    />
                    <pui-heading input={order.headline} textSize='large' spacingTop="small" />
                    <pui-text input={order.subline} spacingTop='small' />
                    <HeavyDivider />
                    <pui-heading input={firstItem.headline} textSize='medium' spacingBottom='small' />
                    {this._generatedMedicalCards()}
                    <AboveTheFoldMarker/>
                    <pui-divider spacingBottom='medium' spacingTop='medium' />
                    <Charges {...order.charges} />
                    {this._generatedRefunds()}
                    <HeavyDivider />
                    <Payment
                        transactions={order.transactions}
                        gqlOrderId={order.id}
                        isUpdatePaymentMethodAllowed={order.isUpdatePaymentMethodAllowed} 
                        paymentMethodLabel={order.orderDetailPageLabels.paymentMethod} 
                        hsaFsaCardLabel={order.orderDetailPageLabels.hsaFsaCard}
                        paymentInfoHeader={order.orderDetailPageLabels.paymentInformation} 
                        updatePaymentMethod={order.orderDetailPageLabels.updatePaymentMethod}
                        paymentLabels={order.orderDetailPageLabels.paymentLabels}
                        suppressPayFixup={suppressPayFixup}
                    />
                    {this._containsInsurances(order) && this._generateInsuranceInformation()}
                    <HeavyDivider />
                    <pui-heading textSize="medium" spacingBottom="small" input={order.orderDetailPageLabels.help} />
                    <Help orderId={order.id} viewInvoice={order.orderDetailPageLabels.viewInvoice} contactCustomerCare={order.orderDetailPageLabels.contactCustomerCare}>
                        {canCancel && <Cancel order={order} />}
                    </Help>
                    <FunctionalMarker/>
            </pui-stack>);
    }

    _containsInsurances(order: Order) {
        const [firstItem] = order.items;
        return firstItem?.insurances && firstItem.insurances.length > 0 && !firstItem.insurances.some(insurance => {
            // Insurance/other card information should be omitted for Arrow.
            return insurance.cardKind.type === "Arrow"
        })
    }

    _generatedRefunds() {
        return (
            this.props.order.items.map((item) =>
                item.refunds ? 
                item.refunds.map((refund) => 
                    <React.Fragment key={refund.reversalId}>
                        <pui-divider spacingTop='small' spacingBottom='small' />
                        <RefundRow refund={refund} labels={this.props.order.orderDetailPageLabels} />    
                    </React.Fragment> 
                ) 
                : <></>
            )
        );
    }

    _generatedMedicalCards() {
        return (<ListDelimiter delimiter={<pui-divider spacingTop='small' spacingBottom='small' />}>
            {this.props.order.items.map((item) =>
                <ExternalLink href={`/dp/${item.rx.asin}`} key={item.itemId}>
                    <ItemMedicationSummaryCard item={item}
                     autoRefillCardLine={<MedicationCardAutoRefillLine isAutoRefill={this.props.order.isAutoRefill} autoRefillTextDisplay={this.props.order.orderingChannelLine}>
                    </MedicationCardAutoRefillLine>}>

                    {item.charges && <pui-stack spacingTop='small'>
                        <MedicationCardChargeLine chargeAmount={item.charges?.grandTotal} insuranceLine={item.insurances && item.insurances[0]?.compositeTailLabel} />
                     </pui-stack>}

                    {item.promotionSavingsLine && <pui-stack spacingTop='small'>
                        <MedicationCardPromotionLine promotionSavingsLine={item.promotionSavingsLine} />
                    </pui-stack>}

                    </ItemMedicationSummaryCard>
                </ExternalLink>
            )}
        </ListDelimiter>);
    }

    _generateInsuranceInformation() {
        return (<pui-stack>
            <HeavyDivider />
            <pui-heading spacingBottom="medium" textSize="medium" input={this.props.order.orderDetailPageLabels.insuranceAndDiscountCardInformation} />
            <ListDelimiter delimiter={<pui-divider spacingTop="medium" spacingBottom="medium" ></pui-divider>} >
                {this.props.order.items
                    .map(item =>
                        item.insurances?
                        item.insurances.map(insurance =>
                          <React.Fragment key={insurance.id}>
                            <pui-stack direction="vertical">
                                <pui-stack-item spacingRight="small">
                                    <pui-text class={`insurance-provider-label`} input={insurance.providerLabel} spacingBottom="small" textSize="small"/>
                                </pui-stack-item>
                                <pui-stack-item>
                                    <pui-text input={insurance.cardHolderId} spacingBottom="small" textSize="medium"/>
                                </pui-stack-item>
                            </pui-stack>
                          </React.Fragment>
                        )
                        : <></>)
                }
            </ListDelimiter>
        </pui-stack>);
    }

}

OrderDetail.contextType = I18NContext;

export default OrderDetail;