import React from 'react';
import ErrorBoundary from '../../ErrorBoundary';
import { I18NContext } from '../../I18NContext';
import ContactCustomerCare from './ContactCustomerCare';

interface QuestionsProps {
    heading: string;
    contactCustomerCare: string;
}
export class Questions extends React.Component<QuestionsProps, {}> {
    render() {

        return (<>
            <pui-heading input={this.props.heading} text='large' spacingBottom='medium' />
            <ErrorBoundary>
            <ContactCustomerCare contactCustomerCare={this.props.contactCustomerCare}/>
            </ErrorBoundary>
        </>);
    }
}

Questions.contextType = I18NContext;

export default Questions;