import 'core-js/es';
import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from "./App";
import I18N from './Strings'
// Server needs to drop the static strings off in a script tag so we can get that global and load it.
// This is an example temporarily...
(function() {
    /*
    const {i18n} = I18NContext._currentValue;
    i18n.addLocale("mx-US", {
        CHARGE_TAX_MESSAGE: "(Incluye impuestos y envío)"
    });
    */
   I18N.add("en-US", {
    ORDER_DATE_FORMAT: "Order Placed: $MONTH $DAY, $YEAR", // not in use
    ORDER_ID_FORMAT: "Order number: $ORDER_ID", // not in use
    BILLING_ADDRESS: 'Billing address:', // not in use. the ChargeSummary page is also not in use
    DELIVERY_ADDRESS: 'Delivery Address', // move to panther done
    SHIPPING_SPEED_LABEL: 'Shipping speed:', // move to panther done
    PAYMENT_INFO_LABEL: 'PAYMENT METHOD', // move to panther done
    HBA_ELIGIBLE: 'FSA or HSA Eligible', // BE already has, move to panther done
    ITEMS_IN_DELIVERY: 'Items in this delivery', // move to panther done
    ITEMS_IN_ORDER: 'Items in this order', // move to panther done
    QUESTIONS: 'Questions?', // move to panther done
    ORDER_INFORMATION: 'Order information', // move to panther done
    HELP: 'Need help with this order?', // move to panther done
    TRACK_THIS_DELIVERY: 'Track this delivery', // move to panther done
    PAYMENT_INFO_HEADER: 'Payment information', // move to panther done
    INSURANCE_INFO_HEADER: 'Insurance and discount card information', // move to panther done
    VIEW_INVOICE: 'View invoice', // move to panther done
    CONTACT_CUSTOMER_CARE: 'Contact customer care', // move to panther done
    CANCEL_ORDER: 'Cancel this order', // not being used in FE, changed BE to Panther done
    HELP_LINK: 'Help', // move to panther done
    ORDER_HISTORY_HEADER: 'Your orders', // move to panther done
    DELIVERY: 'Delivery', // move to panther done
    ORDER: 'Order', // move to panther done
    INVOICE: 'Invoice', // move to panther done
    UPDATE_PAYMENT_METHOD_TEXT: 'Please update your payment method', // move to panther done
    PAST_ORDER_HEADER: 'Past orders', // move to panther done
    PAST_ORDER_SUBLINE: 'Last 90 days', // move to panther done
    PAST_ORDER_SEE_MORE_ORDERS: 'See more', // move to panther done, use seeMoreOrders
    SEE_MORE_ORDERS_LOADING: 'Loading', // move to panther done
    SEE_MORE_ORDERS_FAILURE: 'We\'re having trouble loading more orders. Try refreshing the page or check back later.', // move to panther done
    OLDER_THAN_90_DAYS_HEADER: 'Orders older than 90 days', // move to panther done

    INV_FSA_HSA_ELIGIBLE_AMOUNT: 'FSA/HSA eligible amount', // not being used in FE, changed BE to Panther done
    INV_ORDER_NUMBER: 'Order&nbsp;#:', // changed BE to Panther done
    INV_ORDER_DATE: 'Order&nbsp;date:', // changed BE to Panther done
    INV_DELIVERY_ADDRES: 'Delivery&nbsp;address:', // change to use above delivery address, changed BE to Panther done
    INV_SHIPPING_SPEED: 'Shipping&nbsp;speed:', // changed BE to Panther done
    INV_PAYMENTS: 'Payments:', // move to panther done
    INV_BILLING_ADDRESS: 'Billing&nbsp;address:', // changed BE to Panther done
    INV_NDC: 'NDC&nbsp;#:', // changed BE to Panther done
    INV_ITEM_QUANTITY: 'Item&nbsp;quantity:', // changed BE to Panther done
    INV_UNIT_PRICE: 'Unit&nbsp;price:', // changed BE to Panther done
    INV_PATIENT: 'Patient:', // changed BE to Panther done
    INV_PRESCRIBER: 'Prescriber:', // changed BE to Panther done
    INV_PRESCRIPTION_NUM: 'Prescription&nbsp;#:', // changed BE to Panther done
    INV_FILLED_ON: 'Filled&nbsp;on:', // changed BE to Panther done
    INV_INSURANCE: 'Insurance:', // changed BE to Panther done
    INV_SOLD_BY: 'Sold&nbsp;by:', // changed BE to Panther done
    INV_NABP_NPI: 'NABP/NPI:', // changed BE to Panther done
    INV_SHIPPING_DATE: 'Shipping&nbsp;date:', // changed BE to Panther done

    CANCEL_LABEL: 'Cancel items in this order', // changed BE to Panther done
    CANCEL_FORM_LABEL: 'What would you like to cancel?', // changed BE to Panther done
    CANCEL_CONTINUE_BUTTON: 'Continue', // changed BE to Panther done
});
})();

ReactDOM.render( <App />, document.getElementById('root'));
