import React from 'react';
import { I18NContext } from '../../I18NContext';
import { PaymentLabels, Transaction } from '../component';
import { TransactionFilters } from '../TransactionFilters'
import { ListDelimiter } from './ListDelimiter';
import ErrorBoundary from '../../ErrorBoundary';
import "./Common.css";
import PaymentBottomSheet from './PaymentBottomSheet';
import { BackupPaymentMethodAlert } from '../common/BackupPaymentMethodAlert';
import { isBackupPaymentMethodUsed } from '../common/PaymentUtils';

interface PaymentProps {
    transactions: Transaction[];
    gqlOrderId: string;
    isUpdatePaymentMethodAllowed: boolean;
    paymentMethodLabel: string;
    hsaFsaCardLabel: string;
    paymentInfoHeader: string;
    updatePaymentMethod: string;
    paymentLabels: PaymentLabels;
    suppressPayFixup: boolean;
}

interface PaymentState {
    button: React.RefObject<any>;
    bottomSheet: React.RefObject<any>;
    apxView?: string;
}

class Payment extends React.Component<PaymentProps, PaymentState> {

    private button: any;
    private bottomSheet: any;
    private host: string;

    constructor(props: any) {
        super(props);
        this.state = {
            button: React.createRef(),
            bottomSheet: React.createRef(),
        }
        this.host = window.location.origin;
    }

    _openBottomSheet(e: any) {
        e.preventDefault();
        this.state.bottomSheet.current.show();

        this.setState({
            apxView: undefined,
        })

        // getAPXWidget(this.props.id)
        //     .then(view => this.setState({ apxView: view }))
        //     .catch(() => this.setState({ apxView: 'Error loading Payments.  Please try again later.' }));
    }

    _closeBottomSheet() {
        this.state.bottomSheet.current.hide();
    }

    _messageEventListenerFunction = (event: any) => {
        if(event.origin === this.host && event.data === 'close') {
            this._closeBottomSheet();
            window.location.reload();
        }
    }

    _generatePaymentRow(transaction: Transaction) {
        // TODO: I doubt the CoPay will work as intended: backend now return Co-payment and Cash in the transaction scenario label. 
        // And I suspect hsa/fsa card does not relate to if it is copy or not. Need more investigation
        const header = transaction.scenario && transaction.scenario.label === "CoPay" ? this.props.hsaFsaCardLabel : this.props.paymentMethodLabel;
        const body = transaction.label;

        const isBackupPaymentMethodUsedAlert = isBackupPaymentMethodUsed(transaction);

        let needFixup = false;
        let canUpdate = false;

        transaction?.annotations.forEach(anotation => {
            if (anotation.annotationType === "PayFixup") {
                canUpdate = needFixup = true;
            }
        })

        if (this.props.isUpdatePaymentMethodAllowed) {
            canUpdate = true;
        }

        if (canUpdate && !this.props.suppressPayFixup) {
            return (<>
                <pui-stack>
                    <pui-stack
                        key={transaction.brand + transaction.tail}
                        ref={this.state.button} onClick={(e: any) => this._openBottomSheet(e)}
                        direction='vertical'
                        class="clickable">
                            <pui-stack-item-button>
                                <pui-text input={body} />
                            </pui-stack-item-button>
                            {needFixup && <pui-stack-item>
                                <pui-stack
                                    ref={this.state.button}
                                    direction='horizontal'
                                    mainAxisArrangement='start'
                                    >
                                    <pui-icon imgClass="status-error-icon" spacingRight="small" />
                                    <pui-text textSize="small" input={this.props.updatePaymentMethod} />
                                </pui-stack>
                            </pui-stack-item>}
                    </pui-stack>
                    <pui-stack>
                        {isBackupPaymentMethodUsedAlert &&
                            <BackupPaymentMethodAlert backupPaymentMethodLabels={transaction.originalPaymentMethod?.backupPaymentMethodLabels} backupPaymentMethodWarning={transaction.originalPaymentMethod?.backupPaymentMethodWarning}></BackupPaymentMethodAlert>}
                    </pui-stack>
                </pui-stack>
            </>
            )
        } else {
            return (
                <>
                    <pui-stack key={transaction.brand + transaction.tail} direction='vertical'>
                        <pui-stack>
                            <pui-text input={header} spacingBottom="mini" textSize="small" spacingTop='medium'/>
                        </pui-stack>

                        <pui-stack>
                            <pui-text input={body} />
                            {isBackupPaymentMethodUsedAlert &&
                                <BackupPaymentMethodAlert backupPaymentMethodLabels={transaction.originalPaymentMethod?.backupPaymentMethodLabels} backupPaymentMethodWarning={transaction.originalPaymentMethod?.backupPaymentMethodWarning}></BackupPaymentMethodAlert>}
                        </pui-stack>
                    </pui-stack>
                </>
            )
        }
    }

    componentDidMount() {
        window.addEventListener('message', this._messageEventListenerFunction);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this._messageEventListenerFunction);
    }

    render() {
        return (
            <>
                <ErrorBoundary>
                    <pui-stack spacingRight="small" >
                        <pui-heading textSize="medium" input={this.props.paymentInfoHeader} />
                        <ListDelimiter delimiter={<pui-divider spacingTop="medium" spacingBottom="medium" ></pui-divider>} >
                            {this.distinctTransactions().map(transaction => this._generatePaymentRow(transaction))}
                        </ListDelimiter>
                    </pui-stack>
                </ErrorBoundary>
                <pui-bottom-sheet id="payment-update-modal" data-csa-c-content-id="payment-update-modal" data-csa-c-type="popup" data-csa-c-slot-id="order-detail" hideLink ref={this.state.bottomSheet}>
                    <PaymentBottomSheet addPaymentTitle={this.props.paymentLabels.addPaymentMethodTitle}
                                        addPaymentText={this.props.paymentLabels.addPaymentMethodText} gqlOrderId={this.props.gqlOrderId}></PaymentBottomSheet>
                </pui-bottom-sheet>
            </>
        );
    }

    /**
     * Ensures transactions get filtered down to brand/tail combinations since that's all we show.
     * A Transaction is actually the brand, tail, and charged amount.
     * In cases where there are refunds/reversals, we see multiple Transactions--one for the charge and another for the reversal.
     * 
     */
    distinctTransactions() {
        return TransactionFilters.DistinctLabels(this.props.transactions);
    }
}

Payment.contextType = I18NContext;

export default Payment;
