import React from 'react';
import { I18NContext } from '../../I18NContext';
import OrderHistoryHeaderLoading from './OrderHistoryHeaderLoading';
import HeavyDivider from '../common/HeavyDivider';
import MedicationCardLoading from '../common/MedicationCardLoading';

export class OrderHistoryLoading extends React.Component {
    render() {
        return (
            <>
                <OrderHistoryHeaderLoading/>
                <HeavyDivider/>
                <div className="pui-loading-heading-large-dark small-margin-left mini-margin-bottom" style={{width: '75%'}}/>
                <div className="pui-loading-text-large small-margin-left small-margin-bottom" style={{width: '50%'}}/>
                <MedicationCardLoading/>
                <MedicationCardLoading/>
                <MedicationCardLoading/>
                <HeavyDivider/>
                <div className="pui-loading-heading-large-dark small-margin-left mini-margin-bottom" style={{width: '75%'}}/>
                <div className="pui-loading-text-large small-margin-left small-margin-bottom" style={{width: '50%'}}/>
                <MedicationCardLoading/>
                <HeavyDivider/>
                <div className="pui-loading-heading-large-dark small-margin-left"/>
                <MedicationCardLoading pastOrder={true}/>
            </>
        )
    }
}

OrderHistoryLoading.contextType = I18NContext;

export default OrderHistoryLoading;