import React from 'react';
import './InvoiceAttributeRow.scss'

class InvoiceAttributeRowLoading extends React.Component {
    render() {
        return (
            <tr className={'table-row-divider'}>
                <td>
                    <div className="pui-loading-text-small small-margin-top small-margin-bottom"/>
                </td>
                <td>
                    <div className="pui-loading-text-small small-margin-top small-margin-bottom"/>
                </td>
            </tr>
        );
    }
}

export default InvoiceAttributeRowLoading;