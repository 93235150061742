import KatalMetricsDriver from '@amzn/katal-metrics/lib/driver/KatalMetricsDriver';
import KatalMetricsDriverSushi from "@amzn/katal-metrics-driver-sushi";
import KatalMetricsDriverConsoleLogJson from "@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson";

interface Window {
    location: Location;
}

interface Location {
    href: string;
}

declare var window: Window;

// disable the 10s buffer in sushi metric driver since the buffer will make multiple component metric into single method name
const sushiOptions = {
    lowPriFlushInterval: 0,
    hiPriFlushInterval: 0,
  };
export class MetricsDriverService {

    driver: KatalMetricsDriver;

    constructor() {
        if (window.location.href.match('pharmacy')) {
            const domain =
                window.location.href.indexOf('pharmacy.amazon.com') !== -1
                    ? 'prod'
                    : 'test'; // note test domain can't emit metrics to igraph due to an known issue: https://i.amazon.com/issues/KAT-7727
            this.driver = new KatalMetricsDriverSushi.Builder()
                .withDomainRealm(domain, 'USAmazon')
                .withSushiClientOptions(sushiOptions)
                .build();
        } else {
            this.driver = new KatalMetricsDriverConsoleLogJson();
        }
    }
}