import React from 'react';
import { Link } from "react-router-dom"

interface PUILinkProps {
    href: string;
    text: string;
    textSize?: string;
    textColor?: string;
    fontWeight?: string;
    replace?: boolean;
    align?: "left" | "right" | "center";
    csaId?: string;
}

const defaultProps = {
    textSize: 'large',
    textColor: 'link',
    fontWeight: 'normal',
    align: 'right',
}

export default (props: PUILinkProps) =>
    <Link to={props.href} replace={props.replace} className={`pui-link text-align-${props.align || defaultProps.align}`}>
        <pui-text id={`link-into-${props.csaId}`} data-csa-c-type="link" data-csa-c-action="navigate" data-csa-c-content-id={`link-into-${props.csaId}`} data-csa-c-slot-id="pui-link" 
                  {...defaultProps} {...props} input={props.text} />
    </Link>