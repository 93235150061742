import React from 'react';
import { I18NContext } from '../../I18NContext';
import CancelForm from './CancelForm';
import { Order } from '../component';
import "./Common.css";
import ErrorBoundary from '../../ErrorBoundary';

interface CancelProps {
    order: Order;
    refresh?: () => void;
}

interface CancelState {
    button: React.RefObject<any>;
    bottomSheet: React.RefObject<any>;
}

class Cancel extends React.Component<CancelProps, CancelState> {

    private button: any;
    private bottomSheet: any;

    constructor(props: any) {
        super(props);
        this.state = {
            button: React.createRef(),
            bottomSheet: React.createRef(),
        }
    }

    componentDidMount() {
        window.dispatchEvent(new CustomEvent("DOMContentLoaded"));
    }

    _openBottomSheet(e: any) {
        e.preventDefault();
        if (this.state.bottomSheet && this.state.bottomSheet.current) {
            this.state.bottomSheet.current?.show();
        }
    }

    render() {
        return (<>
            <pui-stack
                direction="vertical"
                ref={this.state.button} onClick={(e: any) => this._openBottomSheet(e)}
                class="clickable">
                <pui-stack-item-button>
                    <pui-text input={this.props.order.orderDetailPageLabels.cancelForm.cancelItemInThisOrder} textSize='medium'/>
                </pui-stack-item-button>
            </pui-stack>
            <ErrorBoundary>
            <pui-bottom-sheet id="cancellation-modal" data-csa-c-content-id="cancellation-modal" data-csa-c-type="popup" data-csa-c-slot-id="order-detail"
                hideLink ref={this.state.bottomSheet}>
                <CancelForm order={this.props.order} refresh={this.props.refresh}/>
            </pui-bottom-sheet>
            </ErrorBoundary>
        </>)
    }
}

Cancel.contextType = I18NContext;

export default Cancel;