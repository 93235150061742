import React from 'react';
import {Annotation, Item, PurchasedItemSummary} from '../component';
import MedicationCard, {CardStyle} from './MedicationCard';
import ItemAnnotations from './ItemAnnotations';

interface ItemMedicationSummaryCardProps {
    item?: Item;
    purchaseItemSummary?: PurchasedItemSummary;
    children?: any;
    autoRefillCardLine?: JSX.Element;
    showSupply?: boolean;
    cardStyle?: CardStyle;
}

export default (props: ItemMedicationSummaryCardProps) => {
    const { item, purchaseItemSummary, autoRefillCardLine, showSupply = true, cardStyle = CardStyle.HEADLINE_LARGE } = props;

    let crossedOut = false;

    const ignoredAnnotations = new Set();
    ignoredAnnotations.add("UpdatePaymentMethod")
    ignoredAnnotations.add("Cancel")

    let annotations: Annotation[] = item?.annotations || purchaseItemSummary?.annotations || [];
    annotations = annotations.filter(annotation => !ignoredAnnotations.has(annotation.annotationType));
    annotations.forEach(annotation => {
        if (annotation.annotationType === 'ReplacedBy') crossedOut = true;;
    });
    
    const imageUrl = item?.rx.imageUrl || purchaseItemSummary?.rxInfo?.imageUrl;
    const headline = item?.rx.headline || purchaseItemSummary?.heading.headline || '';
    const subline = item?.rx.subline || purchaseItemSummary?.heading.subline;

    const annotationsTextColor = cardStyle === CardStyle.HEADLINE_MED_50PX_IMG ? "black" : undefined;
    return <MedicationCard headline={headline} subline={subline} imageURL={imageUrl} crossedOut={crossedOut}
                        autoRefillCardLine={autoRefillCardLine} showSupply={showSupply} cardStyle={cardStyle}
                        purchaseId={purchaseItemSummary?.purchaseId} orderId={purchaseItemSummary?.orderId}>
                <ItemAnnotations annotations={annotations} textSize="small" textColor={annotationsTextColor}/>
                {props.children}
        </MedicationCard>
}
