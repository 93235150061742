import React, { Component } from 'react';
import { I18NContext } from '../../I18NContext';

// Derived from https://code.amazon.com/packages/ApexDashboardHZAVingilot/blobs/mainline/--/webapp/WEB-INF/tags/pyo/rx-card.tag

export enum CardStyle {
    HEADLINE_LARGE,
    HEADLINE_MED_50PX_IMG
}

interface MedicationCardProps {
    headline: string;
    subline?: string;
    imageURL?: string;
    crossedOut?: boolean;
    autoRefillCardLine?: JSX.Element;
    showSupply?: boolean;
    cardStyle?: CardStyle;
    purchaseId?: string;
    orderId?: string;
}

/**
 * Reusable Component to show medication data
 */
class MedicationCard extends Component<MedicationCardProps, {}> {
    render() {
        const { headline, subline, imageURL, crossedOut, autoRefillCardLine, showSupply=true,
            cardStyle=CardStyle.HEADLINE_LARGE } = this.props;
        const styling = crossedOut ? { textDecorationLine: 'line-through' } : {};
        const verticalAlignment = showSupply ? "start" : "center";
        const imgSize = cardStyle === CardStyle.HEADLINE_MED_50PX_IMG ? "50px" : "48px";

        const img = imageURL ? (<img
            src={imageURL}
            style={{maxWidth: imgSize, maxHeight: imgSize, minWidth: imgSize, width: "auto", height: "auto", objectFit: "contain", marginTop: '15px'}}
            className="aok-align-center-horizontally"
            alt=""
        />) : (<pui-icon imgClass='rx-bottle-icon' style={{height: imgSize, width: imgSize}}/>)

        const detailsSpacingLeft = cardStyle === CardStyle.HEADLINE_LARGE ? "small" : "med-small";
        return (<pui-stack
            direction="horizontal"
            secondaryAxisArrangement={verticalAlignment}
            mainAxisArrangement="start"
            data-purchase-id={this.props.purchaseId}
            data-order-id={this.props.orderId}>
            <pui-stack-item>
                {img}
            </pui-stack-item>
            <pui-stack-item spacingLeft={detailsSpacingLeft} overflow="true" spacingTop="med-small">
                <pui-stack direction="vertical">
                    <pui-stack-item overflow="true" spacingbottom="mini">
                        <pui-text
                            style={styling}
                            data-ref="headline"
                            textSize={cardStyle === CardStyle.HEADLINE_LARGE ? "large" : "medium"}
                            fontweight="bold"
                            input={headline}
                        />
                    </pui-stack-item>
                    {showSupply && this._generateSupply(styling, subline)}
                    {autoRefillCardLine}
                    <pui-stack-item overflow="true">
                    {this.props.children}
                    </pui-stack-item>
                </pui-stack>
            </pui-stack-item>
        </pui-stack>
        );
    }

    private _generateSupply(styling: { textDecorationLine: string } | {}, subline: string | undefined) {
        return <pui-stack-item overflow="true">
            <pui-text
                style={styling}
                data-ref="rx-supply-label"
                input={subline}/>
        </pui-stack-item>;
    }
}

MedicationCard.contextType = I18NContext;


export default MedicationCard;
