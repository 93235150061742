import React, { Component } from 'react';
import { I18NContext } from '../../I18NContext';
import './AutoRefill.css';
import Urls from '../../Urls';

interface PaymentBottomSheetProps {
    gqlOrderId: string;
    addPaymentTitle: string;
    addPaymentText: string;
}

class PaymentBottomSheet extends Component<PaymentBottomSheetProps, {}> {

    render() {

        const {gqlOrderId, addPaymentTitle, addPaymentText} = this.props;

        let iframeUrl = Urls.PAYMENT_PORTAL_URL + gqlOrderId;

        let paymentRedirectUrl = Urls.PAYMENT_REDIRECT_URL + gqlOrderId;

        let iframeHeight = '300px';
        
        return (
            <pui-section id="payment-bottom-sheet" scrollable>
                    <iframe id="card-to-update-payment-method" data-csa-c-content-id="card-to-update-payment-method" data-csa-c-type="card" data-csa-c-slot-id="payment-update-modal" sandbox="allow-same-origin allow-scripts allow-forms" title="APX View" src={iframeUrl} style={{width: "100%", height: iframeHeight, overflow : 'scroll'}}></iframe>
                    {<a href={paymentRedirectUrl}>
                        <pui-box id="card-to-add-payment-method" data-csa-c-content-id="card-to-add-payment-method" data-csa-c-type="card" data-csa-c-slot-id="payment-update-modal" spacingTop="small" overflow="true" style={{width: "100%", height:"145px"}}>
                            <pui-section paddingTop="20px" paddingBottom="20px" paddingLeft="10px" paddingRight="0px" style={{height:"145px"}}>
                                <pui-stack direction='vertical'>
                                    <pui-stack-item-button overflow="true" buttonPadding="none" style={{width: "105%"}}>
                                        <pui-heading input={addPaymentTitle} textSize="small"></pui-heading>
                                        <pui-text textSize="small" spacingTop="small" input={addPaymentText}></pui-text>
                                    </pui-stack-item-button>
                                </pui-stack>
                            </pui-section>
                        </pui-box>
                    </a>
                    }
            </pui-section>
        );
    }
}

PaymentBottomSheet.contextType = I18NContext;

export default PaymentBottomSheet;