interface Window {
  location: Location;
}

interface Location {
  replace(value: string): void;
}

declare var window: Window;

const orderHistoryQuery = `query($since: Date) {
  purchaseHistory(since: $since) {
      upcoming {
        ...itemSummaryGroups
      }
      past {
        ...itemSummaryGroups
      }
      labels {
        yourOrders
        delivery
        itemsInThisDelivery
        deliveryAddress
        seeMoreOrders
        seeMoreOrdersLoading
        seeMoreOrdersFailure
        ordersOlderThan90Days
        questions
        contactCustomerCare
        helpLink
        pastOrders
        pastOrdersSubline
        orderId
        noUpcomingOrders
        getMedicationOrderStatements
        upcomingOrdersTab
        pastOrdersTab
        trackDelivery
        seeDetails
      }
      iosDevice
      androidDevice
      mShop
  }
}

fragment itemSummaryGroups on ItemGroupSummary {
  headline
  headlineAbbr
  subline
  orderingChannelLine
  note
  id
  secondaryId
  orderDate
  isRebillOrder
  bypassTracker
  address {
    lines
  }
  itemGroups {
    id
    headline
    annotations {
      label
      annotationType
    }
    itemSummaries {
      id
      orderId
      purchaseId
      isAutoRefill
      rxInfo {
        imageUrl
      }
      annotations {
        label
        annotationType
      }
      heading {
        headline
        subline
      }
    }
  }
}
`

const orderDetailsQuery = `query ($orderId: ID!) {
  order(id: $orderId) {
    id
    orderId
    purchaseId
    bypassTracker
    csrf {
      token
      header
      parameter
    }
    orderDetailPageLabels {
      yourOrders
      delivery
      tracking
      order
      paymentMethod
      hsaFsaCard
      paymentInformation
      updatePaymentMethod
      help
      viewInvoice
      contactCustomerCare
      insuranceAndDiscountCardInformation
      refund
      refundOn
      refundInformation
      refundTotal
      taxRefund
      cancelForm {
        ...cancelFormLabels
      }
      redirectCancellationMessages {
        ...redirectCancellationMessages
      }
      paymentLabels {
        ...paymentLabels
      }
    }
    isUpdatePaymentMethodAllowed
    transactions {
      id
      tail
      brand
      charge
      label
      annotations {
        annotationType
        label
      }
      when {
        value
        day
        dayName
        month
        year
      }
      originalPaymentMethod {
        ...originalPaymentMethod
      }
    }
    headline
    subline
    reverseRebillOrderDetails {
      ...reverseRebillOrderDetails
    }
    orderingChannelLine
    isAutoRefill
    charges {
      item
      itemLabel
      shipping
      shippingLabel
      couponSaving
      couponSavingLabel
      totalBeforeTax
      totalBeforeTaxLabel
      grandTotal
      grandTotalLabel
      salesTax
      salesTaxLabel       
    }
    items {
      id
      itemId
      headline
      insurances {
        payerRank
        compositeTailLabel
        cardHolderId
        tailLabel
        providerLabel
        cardKind {
          label
          type
        }
      }
      charges {
        copay
        copayLabel
        unitPrice
        unitPriceLabel
        item
        itemLabel
        shipping
        shippingLabel
        couponSaving
        couponSavingLabel
        totalBeforeTax
        totalBeforeTaxLabel
        grandTotal
        grandTotalLabel
      }
      status {
        status
        label
      }
      rx {
        asin
        imageUrl
        prescriber
        prescriptionNumber
        ndc11
        id
        filledOn {
          month
          day
          dayName
          year
          value
                }
          headline
          subline
          autoRefillLine
      }
      annotations {
        annotationType
        label
      }
      refunds {
        reversalId
        creationDate
        refundTotal {
          ...refundAmount
        }
        taxRefundTotal {
          ...refundAmount
        }
        refundMonetaryComponents {
          name
          nameLabel
          baseAmount {
            ...refundAmount
          }
          taxAmount {
            ...refundAmount
          }
          totalAmount {
            ...refundAmount
          }
        }
      }
      promotionSavingsLine
    }
  }
}

fragment paymentLabels on PaymentLabels {
  paymentDeclineAlertTitle
  paymentDeclineAlertText
  addPaymentMethodTitle
  addPaymentMethodText
}


fragment redirectCancellationMessages on RedirectCancellationMessages {
  cancellationSuccessRedirectTitle
  cancellationPendingRedirectTitle
  cancellationPendingAutoRefillRedirectTitle
  cancellationPendingAutoRefillRedirectMessage
  cancellationSuccessAutoRefillRedirectMessage
}

fragment originalPaymentMethod on OriginalPaymentMethod {
  backupPaymentMethodLabels {
    backupPaymentMethodUsedTitle
    backupPaymentMethodInformationalText
    learnMore
  }
  backupPaymentMethodWarning
}

fragment cancelFormLabels on CancelFormLabels {
  cancelItemInThisOrder
  cancelButton
  cancelFormTitle
  cancelFormMessage
  cancelPending
  cancelFailed
  cancelSuccess
  cancelTitle
  cancelReason {
    cancelReasonLabel
    cancelReasonSelectLabel
    cancelReasonFilterOptions {
        key
        value
    }
  }
}


fragment refundAmount on RefundAmount {
  amount
  currency
}

fragment reverseRebillOrderDetails on ReverseRebillOrderDetails {
  rebillOrderId
  parentOrderId
  rebillOrderType
  parentOrderURL

}
`;


const deliveryDetailsQuery = `query ($id: ID!) {
  delivery(id: $id) {
    subline
    note
    eta
    label
    lastStepProgress
    status {
      status
    }
    address {
      lines
    }
    events {
      headline
      subline
      note
    }
    lastStepProgress
    labels {
      trackThisDelivery
    }
  }
}`

const invoiceDetailsQuery = `
query ($invoiceId: ID!) {
    order(id: $invoiceId) {
      id
    orderId
    purchaseId
    bypassTracker
    invoicePageLabels {
      yourOrders
      delivery
      tracking
      order
      invoice
      orderId
      orderDate
      shipSpeed
      payments
      orderInformation
      fsaHsaEligibleAmount
      deliveryAddress
      billingAddress
      ndcId
      itemQuantity
      unitPrice
      patient
      prescriptionId
      prescriber
      insurance
      soldBy
      nabpNpi
      shippingDate
      filledOn
      transactionOn
    }
    headline
    subline
    reverseRebillOrderDetails {
      ...reverseRebillOrderDetails
    }
    status {
      label
      status
    }
    orderDate
    patient
    hbaEligibleAmount
    annotations {
      annotationType
      label
    }
    orderedOn {
      value
    }
    transactions {
      id
      tail
      brand
      charge
      label
      when {
        ...date
      }
      address {
        lines
      }
      annotations {
        annotationType
        label
      }
    }
    charges {
      ...charges
    }
    items {
      drugType
      hbaEligibility {
        hbaEligible
        label
      }
      patient {
        name
      }
      delivery {
        address {
          lines
        }
      }
      id
      headline
      subline
      soldBy {
        sellerName
        address {
          lines
        }
      }
      shipmentDate {
        ...date
      }
      annotations {
        annotationType
        label
      }
      patient {
        name
      }
      charges {
        unitPrice
        unitPriceLabel
        ...charges
      }
      status {
        status
        label
      }
      rx {
        id
        headline
        subline
        imageUrl
        prescriber
        prescriptionNumber
        ndc11
        filledOn {
          ...date
        }
        asin
        nabp
        quantity {
          unit
          number
        }
      }
      shipSpeed {
        description
        duration
      }
      insurances {
        payerRank
        tailLabel
        cardKind {
          label
          type
        }
      }
    }
  }
}

fragment reverseRebillOrderDetails on ReverseRebillOrderDetails {
  rebillOrderId
  parentOrderId
  rebillOrderType
  parentOrderURL

}

fragment date on HumanizedDate {
  value
  day
  dayName
  month
  year
}
fragment charges on Charges {
  item
  itemLabel
  salesTax
  salesTaxLabel
  shipping
  shippingLabel
  couponSaving
  couponSavingLabel
  totalBeforeTax
  totalBeforeTaxLabel
  grandTotal
  grandTotalLabel
  hbaEligibleTotal
  hbaEligibleTotalLabel
}`;

const dispenseStatementPageQuery = `query {
  dispenseStatementPage  {
      labels {
          medicationStatements
          chooseYear
          info
          download
          downloadProgress
          downloadSuccess
          downloadError
          continueButton
          downloadAgain
          noStatementError
      }
      filterOptions {
          label
          value
          parameterList {
              key
              value
          }
      }
  }
}
`

const orderTrackerQuery = `query ($id: ID!, $secondaryId: ID) {
  orderTracker(id: $id, secondaryId: $secondaryId) {
      headline
      subline
      labels {
        tracking
        orderSummary
        deliveryInformation
        timeAlert
        reportDamagedOrMissingMedications {
          title
          content
        }
      }
      trackerSteps {
        label
        completionDate
        status
      }
      delivery {
        subline
        note
        eta
        label
        lastStepProgress
        trackingNumber
        carrier
        status {
          status
        }
        events {
          headline
          subline
          note
        }
        lastStepProgress
        labels {
          trackThisDelivery
        }
        deliveryPhoto {
          photoUrl
        }
      }
  }
}
`

const featureFlagsQuery = `query {
  featureFlags
}
`

const gqlFetch = (query: string, variables = {}) => fetch('/your-orders-api/graphql', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  credentials: 'same-origin',
  body: JSON.stringify({ query, variables })
})
  .then((response: any) => {
    const {status, headers} = response;
    
    switch (status) {
      case 401:
      case 404: {
        if (headers) {
          const location = headers.get("location");
          if (location) {
            window.location.replace(location);
          } 
        }
        throw new Error();

        // TODO: Add post to public APIGW for collecting client-side errors
        // Either GQL returned errors, or network errors

      }
      default: {
        return response.json();
      }
    }
  })
  

export const getOrderHistory = (since: Date = new Date()) => gqlFetch(orderHistoryQuery, { since })

export const getDeliveryDetails = (id: string) => gqlFetch(deliveryDetailsQuery, { id })

export const getOrderDetails = (orderId: string) => gqlFetch(orderDetailsQuery, { orderId })

export const getInvoiceDetails = (invoiceId: string) => gqlFetch(invoiceDetailsQuery, { invoiceId })

export const getDispenseStatementPage = () => gqlFetch(dispenseStatementPageQuery, {})

export const getOrderTrackerDetails = (id: string, secondaryId?: string) => gqlFetch(orderTrackerQuery, { id, secondaryId })

export const getFeatureFlags = () => gqlFetch(featureFlagsQuery, {})
