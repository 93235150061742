import React, {useState} from 'react';
import OrderHistoryHeader from './OrderHistoryHeader';
import { ItemGroupSummary, PurchaseHistory } from "../component";
import OlderOrdersComponent from './OlderOrdersComponent';
import OrderHistoryRedirectionComponentRow from './OrderHistoryRedirectionComponent'
import DispenseStatementBottomSheet from "./DispenseStatementBottomSheet";
import { CategoryTabs } from "../common/CategoryTabs";
import ItemGroupCard from "../common/ItemGroupCard";
import {AboveTheFoldMarker, CriticalFeatureMarker, FunctionalMarker} from "../csa/markers";


interface OrderHistoryProps {
    purchaseHistory: PurchaseHistory;
    getOrderHistory: (since: Date) => any;
    orderHistoryRedirectionComponentRow?: OrderHistoryRedirectionComponentRow;
}

function OrderHistory(props: OrderHistoryProps) {
    const categories = [props.purchaseHistory.labels.upcomingOrdersTab, props.purchaseHistory.labels.pastOrdersTab];
    const { upcoming } = props.purchaseHistory;
    const [selectedCategory, setSelectedCategory] = useState<number>(0);

  const _generateUpcomingItemGroupSummary = () => {
        return props.purchaseHistory.upcoming.map(summary =>{ return _processItemGroups(summary)});
    }

    const _generatePastItemGroupSummary = () => {
        const previousOrders = [...props.purchaseHistory.past]

        if(previousOrders.length === 0) {
            return null;
        } else {
            return (<>
                {previousOrders.length > 0 && <>
                    {previousOrders.map(summary => _processItemGroups(summary))}
                </>
                }
            </>);
        }
    }

    const _processItemGroups = (itemGroupSummary: ItemGroupSummary) => {
        return (
            <ItemGroupCard
                key={itemGroupSummary.id}
                itemGroupSummary={itemGroupSummary}
                trackingLinkLabel={props.purchaseHistory.labels.trackDelivery}
                orderDetailsLinkLabel={props.purchaseHistory.labels.seeDetails}
                bypassTracker={itemGroupSummary.bypassTracker}
            />);
    }

    const _generateHeader= () => {
        return (
            <pui-stack>
                {props.orderHistoryRedirectionComponentRow?props.orderHistoryRedirectionComponentRow:<OrderHistoryRedirectionComponentRow isRedirected={false}/>}
                <OrderHistoryHeader headline = {props.purchaseHistory.labels.yourOrders} helpLink={props.purchaseHistory.labels.helpLink}/>
                {
                    <DispenseStatementBottomSheet
                        getMedicationOrderStatements={props.purchaseHistory.labels.getMedicationOrderStatements}
                        iosDevice={props.purchaseHistory.iosDevice}
                        androidDevice={props.purchaseHistory.androidDevice}
                        mShop={props.purchaseHistory.mShop}/>
                }
            </pui-stack>
        )
    }

    return (
        <pui-stack id="order-history-page" data-csa-c-content-id="order-history-page" data-csa-c-type="card" data-csa-c-slot-id="order-history">
            {_generateHeader()}
            <pui-stack-item spacingTop="mini" spacingBottom="med-small">
                <CategoryTabs onChange={setSelectedCategory} selectedCategory={selectedCategory} categories={categories}/>
                <AboveTheFoldMarker/>
                <>
                    <pui-divider class="mobile-only" style={{ height: '1px', width: '100vw', marginLeft: '-20px', position: 'relative' }} />
                    <pui-divider class="tablet-and-desktop-only " style={{ height: '1px', width: '100%', position: 'relative' }} />
                </>
            </pui-stack-item>
            {(!upcoming || upcoming.length === 0) &&
                <pui-stack
                    direction="vertical"
                    secondaryAxisArrangement="center"
                    spacingTop="medium" style={{ width: '100%', border: '2px dashed lightgray', borderRadius: '12px', display: selectedCategory === 0 ? 'block' : 'none' }}>
                    <pui-stack-item>
                        <pui-text input={props.purchaseHistory.labels.noUpcomingOrders} spacingTop="medium" spacingBottom="medium" textColor="grey" />
                    </pui-stack-item>
                </pui-stack>}
            <div style={{display: selectedCategory === 0 ? 'block' : 'none'}}>
                {_generateUpcomingItemGroupSummary()}
            </div>
            <CriticalFeatureMarker/>
            <div style={{display: selectedCategory === 1 ? 'block' : 'none'}}>{_generatePastItemGroupSummary()}
            <FunctionalMarker/>
            <OlderOrdersComponent
                getOrderHistory={props.getOrderHistory}
                _processItemGroups={_processItemGroups}
                purchaseHistory={props.purchaseHistory}
                listDelimiter={<></>}
                />
            </div>
        </pui-stack>
    );
}

export default OrderHistory;
