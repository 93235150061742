import React from 'react';

import { Annotation } from "../component";

interface ItemAnnotationProps {
    annotations: Annotation[];
    textSize?: string;
    textColor?: string;
}

function generateAnnotationLine(annotation: Annotation, textSize: string, textColor?: string) {
    const icon = annotation.annotationType === "PayFixup"
        ? <pui-icon imgClass="status-error-icon" spacingRight="small" />
        : <></>;

    if (textColor === undefined) {
        textColor = annotation.annotationType === "ReplacedBy"
        || annotation.annotationType === "Replaces"
            ? 'grey' : 'black';
    }

    return (<pui-stack
        key={annotation.label}
        style={{ textDecoration: "none" }}
        direction="horizontal"
        mainAxisArrangement="start"
    >
        {icon}
        <pui-text input={annotation.label} textColor={textColor} textSize={textSize} />
    </pui-stack >)
}

export default ({annotations, textSize="medium", textColor}: ItemAnnotationProps) => {
    const duplicates = new Set();

    const suppressPayFixup = annotations.some(
        (annotation) => annotation.annotationType === "SuppressPayFixup");

    const annotationChild = annotations
        .filter(annotation => annotation.annotationType !== "Cancel")
        .filter(annotation => annotation.annotationType !== "SuppressPayFixup")
        .filter(annotation => suppressPayFixup?annotation.annotationType !== "PayFixup":true)
        .filter(annotation => {
            const isDuplicate = duplicates.has(annotation.annotationType);
            duplicates.add(annotation.annotationType);
            return !isDuplicate;
        })
        .map(annotation => {
            duplicates.add(annotation.annotationType)
            return generateAnnotationLine(annotation, textSize, textColor);
        });

        return (<> 
            {annotationChild}
        </>)
}