import React, {SyntheticEvent, useRef} from "react";
import ReportDamagedOrMissingMedicationsContent from "./ReportDamagedOrMissingMedicationsContent";
import {ReportDamagedOrMissingMedications} from "../component";

export interface ReportDamagedOrMissingMedicationsBottomSheetProps {
  reportDamagedOrMissingMedications: ReportDamagedOrMissingMedications
}

export default function ReportDamagedOrMissingMedicationsBottomSheet(props: ReportDamagedOrMissingMedicationsBottomSheetProps) {
  const bottomSheetRef = useRef<any>(null);
  const showBottomSheet = (e: SyntheticEvent) => {
    e.preventDefault()
    bottomSheetRef?.current?.show();
  }

  return <>
    <pui-button id="button-to-report-damaged-or-missing-medications"
                data-csa-c-type="button"
                data-csa-c-action="click"
                data-csa-c-content-id="button-to-report-damaged-or-missing-medications"
                data-csa-c-slot-id="report-damaged-or-missing-medications"
                label={props.reportDamagedOrMissingMedications.title}
                textColor="black-color"
                theme="secondary"
                spacingTop="medium"
                onClick={showBottomSheet}/>
    <pui-bottom-sheet ref={bottomSheetRef} spacingBottom={"small"}>
      <ReportDamagedOrMissingMedicationsContent {...props.reportDamagedOrMissingMedications}/>
    </pui-bottom-sheet>
  </>
}