import React from 'react';
import ChargesRow from "./ChargesRow";
import { Charge } from './component';

// Derived from https://code.amazon.com/packages/ApexDashboardHZAVingilot/trees/mainline/--/webapp/WEB-INF/views/mobile/dashboard/invoice

export default (props: Charge) => {
    return (<pui-stack>
            <table style={{ width: '100%' }}>
                <tbody>
                    <ChargesRow label={props.itemLabel} value={props.item} />
                    <ChargesRow label={props.shippingLabel} value={props.shipping} />
                    { props.couponSaving && <ChargesRow label={props.couponSavingLabel} value={props.couponSaving} />}
                    <ChargesRow label={props.unitPrice} value={props.unitPrice} />
                    <ChargesRow label={props.totalBeforeTaxLabel} value={props.totalBeforeTax} />
                    <ChargesRow label={props.salesTaxLabel} value={props.salesTax} />
                    <ChargesRow label={props.hbaEligibleTotalLabel} value={props.hbaEligibleTotal} />
                    <ChargesRow label={props.grandTotalLabel} value={props.grandTotal} fontWeight="bold" />
                </tbody>
            </table>
    </pui-stack>)
}




