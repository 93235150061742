
export const formatMonDDYYY = (input: string, locale: string = 'en-US'): string => {
    if (!input) return '';

    const date = new Date(input);

    const dateTimeFormat = new Intl.DateTimeFormat(locale, { year: 'numeric', month: 'short', day: '2-digit' })
    const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);

    return `${month} ${day}, ${year}`;
}

export const formatCurrency = (amount: number, currency: string, locale: string = 'en-US'): string => {
    if (!currency) return '';

    // it will auto pad 2 decimal places
    return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(amount);
}

// always negative as the return amount. (-0.00 is a valid amount in return field)
export const formatReturnAmount = (input : string) => {
    let parsed : number = parseFloat(input);
    return -Math.abs(parsed);
}
