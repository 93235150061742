import React from 'react';
import RefundRow from './RefundRow';

export default class RefundLoading extends React.Component {

    render() {
        return (
            <RefundRow loading={true}/>
        )
    }
}
