import React, { FunctionComponent, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';

interface ForwardLinkProps {
    url: string;
    csaId: string;
    onClick?: () => void;
}

const handleClickEvent = (event: SyntheticEvent, props: ForwardLinkProps) => {
    if (props.onClick) {
        event.preventDefault();
        props.onClick()
    }
}

export const ForwardLink: FunctionComponent<ForwardLinkProps> = (props) =>
    <Link id={`forward-link-to-${props.csaId}`} data-csa-c-type="link" data-csa-c-action="navigate" data-csa-c-content-id={`forward-link-to-${props.csaId}`}
          data-csa-c-slot-id="forward-link" to={props.url} style={{ textDecoration: 'none' }} onClick={(e) => handleClickEvent(e, props)}>
        <pui-stack direction='vertical'>
            <pui-stack-item-button overflow="true" buttonPadding="none">
                {props.children}
            </pui-stack-item-button>
        </pui-stack>
    </Link>


export const ExternalForwardLink: FunctionComponent<ForwardLinkProps> = (props) =>
    <a id={`external-link-to-${props.csaId}`} data-csa-c-type="link" data-csa-c-action="navigate" data-csa-c-content-id={`external-link-to-${props.csaId}`}
       data-csa-c-slot-id="external-forward-link" href={props.url} style={{ textDecoration: 'none' }} onClick={(e) => handleClickEvent(e, props)}>
        <pui-stack direction='vertical'>
            <pui-stack-item-button overflow="true" buttonPadding="none">
                {props.children}
            </pui-stack-item-button>
        </pui-stack>
    </a>

