import React from 'react';
import { BreadCrumbLoading } from '../common/BreadCrumb';
import HeavyDivider from '../common/HeavyDivider';
import InvoiceItemSummaryLoading from './InvoiceItemSummaryLoading';

class InvoiceComponentLoading extends React.Component {

    render() {
        return (
            <pui-stack style={{width: "100%"}}>
                <BreadCrumbLoading/>
                <div className="pui-loading-heading-large-dark small-margin-top" style={{width: '75%'}}/>
                <div className="pui-loading-text-medium mini-margin-top" style={{width: '50%'}}/>
                <HeavyDivider />
                <InvoiceItemSummaryLoading/>
                <HeavyDivider />
                <InvoiceItemSummaryLoading/>
            </pui-stack>
        )

    }
}

export default InvoiceComponentLoading;
