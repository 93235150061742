import React from 'react';

interface PromotionLineProps {
    promotionSavingsLine: string;
}

export default function MedicationCardPromotionLine({ promotionSavingsLine }: PromotionLineProps) {
    return (
        <pui-stack-item overflow="true">
            <pui-stack
                direction="horizontal"
                mainAxisArrangement="start"
                secondaryaxisarrangement="start"
                spacingBottom="small"
            >
                <pui-stack-item overflow="true">
                    <pui-text
                        input={promotionSavingsLine}
                        textSize="small"
                    />
                </pui-stack-item>
            </pui-stack>
        </pui-stack-item>
    );
}