import React from 'react';
import './ExternalLink.css';

interface Window {
    location: Location;
}

interface Location {
    replace(value: string): void;
    origin: string;
}

declare var window: Window;

interface ExternalLinkProps {
    href: string;
    children: any;
}

export default (props: ExternalLinkProps) =>
    <a className="external-link" href={window.location.origin + props.href} >
        {props.children}
    </a>
