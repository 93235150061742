import React from 'react';

export class OrderHistoryHeaderLoading extends React.Component {
    render() {
        return (
            <pui-stack direction="horizontal">
                <pui-stack-item>
                    <pui-stack direction="horizontal">
                        <pui-icon imgClass="dashboard-default-shipping-icon" spacingRight="small" />
                        <div className="pui-loading-heading-extra-large-dark" />
                    </pui-stack>
                </pui-stack-item>
                    
                <pui-stack-item>
                    <pui-stack>
                        <div className="pui-loading-text-medium-dark" />
                    </pui-stack>
                </pui-stack-item>
            </pui-stack>
        )
    }
}

export default OrderHistoryHeaderLoading;