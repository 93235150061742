import React from 'react';
import { I18NContext } from '../../I18NContext';
import { Item } from '../component';

interface CancelFormSelectProps {
    item: Item;
    isAutoRefill?: boolean;
    selectButton: (disable: boolean) => void;
}

interface CancelFormSelectState {
    buttonRef: React.RefObject<any>;
    cardRef: React.RefObject<any>;
    selected: boolean;
}

class CancelFormSelect extends React.Component<CancelFormSelectProps, CancelFormSelectState> {

    constructor(props: any) {
        super(props);

        this.state = {
            buttonRef: React.createRef(),
            cardRef: React.createRef(),
            selected: true
        }
    }

    canCheckButtonRef() {
        return this.state.buttonRef.current && typeof this.state.buttonRef.current.check === 'function';
    }

    canSelectCardRef() {
        return this.state.cardRef.current && typeof this.state.cardRef.current.select === 'function';
    }

    enableSelectButton() {
        if (this.canCheckButtonRef()) {
            this.state.buttonRef.current.check();
        }
        if (this.canSelectCardRef()) {
            this.state.cardRef.current.select();
        }
    }

    disableSelectButton() {
        if (this.canCheckButtonRef()) {
            this.state.buttonRef.current.uncheck();
        }
        if (this.canSelectCardRef()) {
            this.state.cardRef.current.deselect();
        }
    }

    setButton() {
        if (this.state.selected) {
            this.enableSelectButton();
        } else {
            this.disableSelectButton();
        }   
    }

    componentDidMount() {
        this.enableSelectButton();
    }

    render() {
        const { item } = this.props;
        //Can sometimes be duplicate of _onSelect to ensure that card and button refs are aligned
        this.setButton();
        return (
            <>
                <pui-selector-item value={item} ref={this.state.cardRef} onClick={() => this._onSelect()}>
                    <pui-section flowDirection='horizontal' mainAxisArrangement='start' secondaryAxisArrangement='start' spacingLeft='medium' spacingTop='small' spacingBottom='small'>
                        <pui-section-column spacingLeft='small' spacingRight='small' spacingBottom='medium' style={{'marginTop':'-5px'}}>
                            <pui-checkbox ref={this.state.buttonRef} onClick={() => this._onSelect()}/>
                        </pui-section-column>
                        <pui-section-column >
                            <pui-text input={item.rx.headline} fontWeight='bold' />
                            <pui-text input={item.rx.subline} />
                            {(this.props.isAutoRefill)  && <pui-text input={item.rx.autoRefillLine}/>}
                        </pui-section-column>
                    </pui-section>
                </pui-selector-item>
            </>
        )
    }

    _onSelect() {
        this.setState({ selected: !this.state.selected });
        this.props.selectButton(this.state.selected);
        this.setButton();
    }


}

CancelFormSelect.contextType = I18NContext;

export default CancelFormSelect;