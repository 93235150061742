import React, { FunctionComponent } from 'react';
import { PaymentLabels } from '../component';
import PaymentBottomSheet from './PaymentBottomSheet';

interface PaymentDeclineAlertProps {
    paymentLabels: PaymentLabels;
    gqlOrderId : string;
}

export const PaymentDeclineAlert: FunctionComponent<PaymentDeclineAlertProps> = (props) => {

    const [paymentDeclineAlertTextOne, paymentDeclineAlertIngress, paymentDeclineAlertTextTwo] = props.paymentLabels.paymentDeclineAlertText.split('%');

    return (
        <pui-banner status="error" padding="med-small" spacingTop="small" spacingBottom="small">
            <pui-stack direction="vertical" paddingRight="20px">
                <pui-heading spacingBottom="small" textSize="small" textcolor="red" input ={props.paymentLabels.paymentDeclineAlertTitle}></pui-heading>
                <pui-stack-item overflow="true">
                    <pui-text inline input={paymentDeclineAlertTextOne}/>
                    <pui-bottom-sheet id="payment-decline-modal" data-csa-c-content-id="payment-decline-modal" data-csa-c-type="popup" data-csa-c-slot-id="order-detail" inline closeable text={paymentDeclineAlertIngress} textSize="medium">
                        <PaymentBottomSheet addPaymentTitle={props.paymentLabels.addPaymentMethodTitle}
                                            addPaymentText={props.paymentLabels.addPaymentMethodText} gqlOrderId={props.gqlOrderId}></PaymentBottomSheet>
                    </pui-bottom-sheet>
                    <pui-text inline input={paymentDeclineAlertTextTwo}/>
                </pui-stack-item>
            </pui-stack>
        </pui-banner>)
    }


