import React from 'react';
import './TrackerSteps.scss';

interface TrackerStepsProps {
    steps: TrackerStep[];
}

export interface TrackerStep {
    label: string,
    eventUpdateLine: string
    status: TrackerStepStatus
}

export enum TrackerStepStatus {
    COMPLETE,
    IN_PROGRESS,
    PENDING
}

function TrackerSteps(props: TrackerStepsProps) {
    function generateStepIcon(status: TrackerStepStatus) {
        switch (status) {
            case TrackerStepStatus.COMPLETE: {
                return <div className={"step-completed-icon"}/>;
            }
            case TrackerStepStatus.PENDING: {
                return <div className={"step-pending-icon"}/>
            }
            case TrackerStepStatus.IN_PROGRESS: {
                return <div className={"step-pending-icon"}/>
            }
        }
    }

    function generateVerticalLine(status: TrackerStepStatus) {
        switch (status) {
            case TrackerStepStatus.COMPLETE: {
                return <div className={"step-completed-vertical-line"}/>;
            }
            case TrackerStepStatus.PENDING: {
                return <div className={"step-pending-vertical-line"}/>;
            }
            case TrackerStepStatus.IN_PROGRESS: {
                return <div className={"step-pending-vertical-line"}/>;
            }
        }
    }

    function generateStep(step: TrackerStep) {
        return <pui-stack direction="horizontal" mainaxisarrangement="start" secondaryaxisarrangement="start">
            <pui-stack-item spacingRight="med-small">
                {generateStepIcon(step.status)}
            </pui-stack-item>
            <pui-stack-item>
                <pui-stack direction="horizontal">
                    <pui-stack-item>
                        <pui-text input={step.label}/>
                    </pui-stack-item>
                    <pui-stack-item>
                        <pui-text input={step.eventUpdateLine} textSize="small" spacingLeft="mini"/>
                    </pui-stack-item>
                </pui-stack>
            </pui-stack-item>
        </pui-stack>;
    }

    function generateSteps(steps: TrackerStep[]) {
        return steps.map((step, i) => {
            if (i+1 === steps.length) {
                return (
                    <div key={i}>
                        {generateStep(step)}
                    </div>
                )
            } else {
                return (
                    <div key={i}>
                        {generateStep(step)}
                        {generateVerticalLine(step.status)}
                    </div>
                )
            }
        });
    }

    return(
        <>
            <pui-stack flex={"true"} flowDirection={"vertical"}>
                {generateSteps(props.steps)}
            </pui-stack>
        </>
    )
}

export default TrackerSteps;