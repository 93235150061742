import React, {useEffect} from 'react';
import {Marker, useCSA, useCsaRouteTracker} from './csa';

export interface CsaMarkerProps {
  readonly name: string;
}

export const CsaMarker = ({name}: CsaMarkerProps) => {
  const {recordMarker} = useCSA();
  useEffect(() => {
    recordMarker(name);
  });
  return null;
};

export const AboveTheFoldMarker = () => {
  return <CsaMarker name={Marker.AboveTheFold}/>;
};

export const CriticalFeatureMarker = () => {
  return <CsaMarker name={Marker.CriticalFeature}/>;
};

export const FunctionalMarker = () => {
  return <CsaMarker name={Marker.Functional}/>;
};

// For simple page that renders content atomically we can record all required Marker
// once data is loaded and main contend is rendered to a user.
export const CsaLatencyMarkers = ({ready}: { ready: boolean }) => {
  if (!ready) {
    return null;
  }
  return (
    <>
      <AboveTheFoldMarker/>
      <CriticalFeatureMarker/>
      <FunctionalMarker/>
    </>
  );
};

export const CsaNewPageTransition = ()=> {
  useCsaRouteTracker()
  return null;
}