import React, { Component } from 'react';

interface MedicationCardLoadingProps {
    pastOrder?: boolean;
}

class MedicationCardLoading extends Component<MedicationCardLoadingProps, {}> {
    render() {
        const { pastOrder } = this.props;

        return (
            <pui-stack
                direction="horizontal"
                spacingTop="small"
                spacingBottom="small"
                secondaryAxisArrangement="center"
                mainAxisArrangement="start">
                <pui-stack-item>
                    <div className="pui-loading-heading-large small-margin-left small-margin-right" style={{height: '40px', width: '40px'}}/>
                </pui-stack-item>
                <pui-stack-item spacingLeft="small">
                    <pui-stack direction="vertical">
                        <pui-stack-item>
                            <div className="pui-loading-text-extra-large-dark mini-margin-bottom" style={{ width: '75%'}}/>
                        </pui-stack-item>
                        <pui-stack-item>
                            <div className="pui-loading-text-extra-large mini-margin-bottom"/>
                        </pui-stack-item>
                        <pui-stack-item>
                            {pastOrder ? <div className="pui-loading-text-extra-large" style={{ width: '85%'}}/> : ''}
                        </pui-stack-item>
                    </pui-stack>
                </pui-stack-item>
            </pui-stack>
        );
    }
}

export default MedicationCardLoading;