import { getFeatureFlags } from "../../accessors/GraphQLAccessor";

class FeatureFlags {
    featureFlagsStrings: String[];

    constructor() {
        this.featureFlagsStrings =  [];
    }

    async pullFeatureFlags() {
        const response = await getFeatureFlags();
        this.featureFlagsStrings = response.data.featureFlags;
        return;
    }

    isDefaultFeatureFlags() {
        return this.featureFlagsStrings.includes("DEFAULT");
    }

    reset() {
        this.featureFlagsStrings =  [];
    }
}

export default FeatureFlags;
