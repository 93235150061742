import React from 'react';

export default () => {
    return (
        // Since the outer-most container is setting padding of 20px all around, 
        // we need the .mobile-only divider to "fill the whole view-width"; 
        // thus, it needs negative left margin to override the padding-left of the outer-most container,
        //  and to use vw instead of percentage on the width.
        // 
        // For desktop/tablet, we do not need such screwiness.  
        <>
            <pui-divider class="mobile-only" style={{ height: '4px', width: '100vw', marginLeft: '-20px', position: 'relative' }} spacingTop="medium" spacingBottom="medium" />
            <pui-divider class="tablet-and-desktop-only " style={{ height: '4px', width: '100%', position: 'relative' }} spacingTop="medium" spacingBottom="medium" />
        </>
    )
}