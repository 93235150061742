import React, { Component } from 'react';
import { I18NContext } from '../../I18NContext';
import './AutoRefill.css';

interface BackupPaymentMethodLearnMorePopupProps {
    backupPaymentMethodInfoText?: string;
}

class BackupPaymentMethodLearnMorePopup extends Component<BackupPaymentMethodLearnMorePopupProps, {}> {
    render() {
        return (
            <pui-section id="payment-bottom-sheet" scrollable>
                <pui-text textSize="medium" input={this.props.backupPaymentMethodInfoText}></pui-text>
            </pui-section>
        );
    }
}

BackupPaymentMethodLearnMorePopup.contextType = I18NContext;

export default BackupPaymentMethodLearnMorePopup;