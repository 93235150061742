import React from 'react';
import { CategoryTab } from "./CategoryTab";

interface CategoryTabsProps {
    selectedCategory: number;
    categories: string[];
    onChange(idx: number): void;
};

export function CategoryTabs({selectedCategory, onChange, categories}: CategoryTabsProps) {
    const handleOnChange = (idx: number) => {
        onChange(idx);
    }

    const renderCategoryTabs = () => {
        return categories.map((category, idx) => {
            return <CategoryTab key={idx} idx={idx} title={category} selected={selectedCategory === idx} onChange={handleOnChange}/>
        });
    };
    return (
        <pui-section
            flowDirection="horizontal"
            mainAxisArrangement="start"
            secondaryAxisArrangement="center">
            {renderCategoryTabs()}
        </pui-section>
    )
}