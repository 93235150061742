import React, { Component } from 'react';
import { I18NContext } from '../../I18NContext';
import './AutoRefill.css';

interface AutoRefillProps {
    isAutoRefill?: boolean;
    autoRefillTextDisplay?: string;
}

class MedicationCardAutoRefillLine extends Component<AutoRefillProps, {}> {
    render() {

        const { isAutoRefill, autoRefillTextDisplay } = this.props;

        return (
            <>
                {isAutoRefill && 
                    (<pui-stack direction='horizontal' mainAxisArrangement='start'>
                        {<pui-icon imgClass='auto-refill-icon'/>}
                        {<pui-text input={autoRefillTextDisplay}/>}
                    </pui-stack>)
                }
            </>
        );
    }
}

MedicationCardAutoRefillLine.contextType = I18NContext;

export default MedicationCardAutoRefillLine;