import React, {useEffect, useRef, useState} from 'react';
import {DispenseStatementFilterOption, DispenseStatementPageLabels} from "../component";
import {getDispenseStatementPage} from "../../accessors/GraphQLAccessor";
import DispenseStatementBottomSheetContent from "./DispenseStatementBottomSheetContent";

interface DispenseStatementBottomSheetProps {
    getMedicationOrderStatements: string,
    iosDevice: boolean,
    androidDevice: boolean,
    mShop: boolean
}

export enum StatementStatus {
    Hidden,
    FilterOptions,
    Downloading,
    Done,
    GenerationError,
    NoStatementError,
}

export interface AbortDownload {
    abortController: AbortController | undefined;
    reason: string | undefined;
}

const abortDownload: AbortDownload = {abortController: undefined, reason: undefined};

function DispenseStatementBottomSheet(props: DispenseStatementBottomSheetProps) {
    const [labels, setLabels] = useState<DispenseStatementPageLabels>();
    const [filterOptions, setFilterOptions] = useState<DispenseStatementFilterOption[]>();
    const [statementStatus, setStatementStatus] = useState<StatementStatus>(StatementStatus.Hidden);
    const bottomSheetRef = useRef<any>(null);

    const showBottomSheet = () => {
        setStatementStatus(StatementStatus.FilterOptions);
        bottomSheetRef?.current?.show();
    }

    const hideBottomSheet = () => {
        bottomSheetRef?.current?.hide();
    }

    const abort = (reason: string) => {
        if (abortDownload.abortController) {
            abortDownload.reason = reason;
            abortDownload.abortController.abort();
        }
    }

    const handleHideBottomSheet = async () => {
        abort("hide");
    }

    useEffect(() => {
        getDispenseStatementPage()
            .then((response: any) => {
                setLabels(response.data.dispenseStatementPage.labels);
                setFilterOptions(response.data.dispenseStatementPage.filterOptions);
            });
    }, []);

    useEffect(() => {
        const current = bottomSheetRef.current;
        if (bottomSheetRef && bottomSheetRef.current) {
            current.addEventListener('hideBottomSheet', handleHideBottomSheet);
            return () => {
                current.removeEventListener('hideBottomSheet', handleHideBottomSheet)
            }
        }
    })

    if (!labels || !filterOptions) return(<pui-link id="link-for-no-options-dispense-statement" data-csa-c-type="link" data-csa-c-action="nothing" data-csa-c-content-id="link-for-no-options-dispense-statement"
                                                    data-csa-c-slot-id="dispense-statement" spacingTop="small" text={props.getMedicationOrderStatements} />);
    return (<>
            <pui-link id="link-to-ingress-into-dispense-statement" data-csa-c-type="link" data-csa-c-action="paginate" data-csa-c-content-id="link-to-ingress-into-dispense-statement"
                      data-csa-c-slot-id="dispense-statement" spacingTop="small" text={props.getMedicationOrderStatements} onClick={showBottomSheet} />
            <pui-bottom-sheet id="dispense-statement-modal" data-csa-c-content-id="dispense-statement-modal" data-csa-c-type="popup" data-csa-c-slot-id="order-history" ref={bottomSheetRef} >
                <DispenseStatementBottomSheetContent
                    labels={labels}
                    filterOptions={filterOptions}
                    showBottomSheet={showBottomSheet}
                    hideBottomSheet={hideBottomSheet}
                    abortDownload={abortDownload}
                    setStatementStatus={setStatementStatus}
                    statementStatus={statementStatus}
                    iosDevice={props.iosDevice}
                    androidDevice={props.androidDevice}
                    mShop={props.mShop}
                />
            </pui-bottom-sheet>
    </>);
}

export default DispenseStatementBottomSheet;