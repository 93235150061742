import React, { FunctionComponent } from 'react';
import BackupPaymentMethodLearnMorePopup from './BackupPaymentMethodLearnMorePopup';
import { BackupPaymentMethodLabels } from '../component';

interface BackupPaymentMethodAlertProps {
    backupPaymentMethodLabels?: BackupPaymentMethodLabels;
    backupPaymentMethodWarning?: string;
}

export const BackupPaymentMethodAlert: FunctionComponent<BackupPaymentMethodAlertProps> = (props) => {
    return (
        <pui-banner direction='vertical' flex-direction="column" status="information" spacingTop="small" spacingBottom="small">
            <pui-stack direction="vertical" flex-direction="column">
                <pui-stack direction="horizontal" mainAxisArrangement="start">
                    <pui-icon imgClass="status-info-icon" spacingRight="mini"/>
                    <pui-heading textSize="small" input ={props.backupPaymentMethodLabels?.backupPaymentMethodUsedTitle}></pui-heading>
                </pui-stack>
                <pui-stack>
                    <pui-stack-item overflow="true" spacingLeft="medium" spacingTop="mini">
                        <pui-text inline input={props.backupPaymentMethodWarning}></pui-text>
                        <pui-bottom-sheet spacingLeft="mini" text={props.backupPaymentMethodLabels?.learnMore} id="backup-payment-method-modal" data-csa-c-content-id="backup-payment-method-modal" data-csa-c-type="popup" data-csa-c-slot-id="order-detail" textSize="medium">
                            <BackupPaymentMethodLearnMorePopup backupPaymentMethodInfoText={props.backupPaymentMethodLabels?.backupPaymentMethodInformationalText}/>
                        </pui-bottom-sheet>
                    </pui-stack-item>
                </pui-stack>
            </pui-stack>
        </pui-banner>)
    }


