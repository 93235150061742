import React from "react"
import { ItemGroupSummary, PurchaseHistory } from '../component'
import { Cache } from 'aws-amplify';
import { Redirect } from 'react-router-dom';
import {getOrderHistory, getOrderTrackerDetails } from '../../accessors/GraphQLAccessor';
import DeliveryDetailsLoading from '../delivery/DeliveryDetailsLoading';

import appMetrics from '../../common/MetricsService';
import MetricsConstants from '../../common/MetricsConstants';
import * as KatalMetrics from '@amzn/katal-metrics';
import TrackerPage from "./TrackerPage";
import { AppContext } from "../../AppContext";

class TrackerHOC extends React.Component<any, any> {

    actionMetricsPublisher: any;
    attemptMetric: any;

    constructor(props: any) {
        super(props);
        this.state = {
            trackingID: null,
            view: <DeliveryDetailsLoading/>
        };
        this.actionMetricsPublisher = appMetrics.getMetricsPublisherForMethod("TrackerDetails");
    }

    selectItemGroupSummary(itemGroupSummary: ItemGroupSummary, purchaseHistory: PurchaseHistory) {
        this.setState({ 
            view: <DeliveryDetailsLoading/> 
        });

        const trackingID = itemGroupSummary.id;
        const secondaryID = itemGroupSummary.secondaryId;

        Cache.setItem("trackingID", trackingID);
        getOrderTrackerDetails(trackingID, secondaryID)
            .then((response: any) => {
                const orderTracker = response.data.orderTracker;
                this.setState({
                    view: (<TrackerPage orderTracker={orderTracker} itemGroupSummary={itemGroupSummary} purchaseHistory={purchaseHistory} />)
                });
                this.attemptMetric.setSuccess();
                this.actionMetricsPublisher.publish(this.attemptMetric);
            })
            .catch(() => 
            {
                //Order Tracker currently has a hard dependency on OrderTracker object; If not available, error
                this.setState({
                    view:<pui-text data-csa-c-content-id="tracker-page-error" data-csa-c-type="card" data-csa-c-slot-id="tracker">Error</pui-text>,
                });
                this.attemptMetric.setFailure();
                this.actionMetricsPublisher.publish(this.attemptMetric);
            }
        )
        this.setState({ trackingID, itemGroupSummary });
    }

    componentDidMount() {
        this.attemptMetric = new KatalMetrics.Metric.TimedAttempt(MetricsConstants.METRIC_LOAD_PAGE_DATA).withMonitor();
        const trackingId = this.props.match.params.trackingId || Cache.getItem("trackingID");
        const [orderHistory]: ItemGroupSummary[] = []
            .concat(this.context.getPurchaseHistory().upcoming, this.context.getPurchaseHistory().past)
            .filter((itemGroup: ItemGroupSummary) => itemGroup?.id === trackingId);
        
        if(orderHistory) {
            this.selectItemGroupSummary(orderHistory, this.context.getPurchaseHistory());
        } else {
            // When a customer enters PYO through Dashboard, they navigate directly to the delivery details component.
            // That means nothing is set in the context since they didn't enter through the order history component. 
            // Fetch for the last 90 days in order history and let's see if a item is found.
            getOrderHistory()
                .then((response: any) => {
                    this.context.addPurchaseHistory(response.data.purchaseHistory);
                    const [orderHistoryResponse] = []
                        .concat(response.data.purchaseHistory.upcoming, response.data.purchaseHistory.past)
                        .filter((itemGroup: ItemGroupSummary) => itemGroup.id === trackingId);
                        
                    if (orderHistoryResponse) {
                        this.selectItemGroupSummary(orderHistoryResponse, this.context.getPurchaseHistory());
                    } else {
                        this.setState({
                            view: <Redirect data-csa-c-content-id="tracker-page-redirection-to-order-history" data-csa-c-type="element" data-csa-c-slot-id="tracker" to="/your-orders"/>
                        });
                    };
                    this.attemptMetric.setSuccess();
                    this.actionMetricsPublisher.publish(this.attemptMetric);
                })
                .catch(() => 
                {
                    this.setState({
                        view: <pui-text id="tracker-order-history-error" data-csa-c-content-id="tracker-order-history-error" data-csa-c-type="element" data-csa-c-slot-id="tracker">Error</pui-text>,
                    });
                    this.attemptMetric.setFailure();
                    this.actionMetricsPublisher.publish(this.attemptMetric);
                })
                    
        }
    }

    render() {
        return (this.state.view);
    }
}

TrackerHOC.contextType = AppContext;

export default TrackerHOC;