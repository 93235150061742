import React from 'react';
import OrderHistory from "./OrderHistory";
import { getOrderHistory } from '../../accessors/GraphQLAccessor';
import OrderHistoryLoading from './OrderHistoryLoading';

import appMetrics from '../../common/MetricsService';
import MetricsConstants from '../../common/MetricsConstants';
import * as KatalMetrics from '@amzn/katal-metrics';
import OrderHistoryRedirectionComponentRow from './OrderHistoryRedirectionComponent'
import { AppContext } from '../../AppContext';

class OrderHistoryHOC extends React.Component<any, any> {

  // metrics: MetricsService; 

  constructor(props: any) {
    super(props);
    this.state = {
      deliveryID: null,
      view: <OrderHistoryLoading/>
    }
    // this.metrics = this.props.metrics;
  }

  componentDidMount() {

    const actionMetricsPublisher = appMetrics.getMetricsPublisherForMethod("OrderHistory");
    const attemptMetric = new KatalMetrics.Metric.TimedAttempt(MetricsConstants.METRIC_LOAD_PAGE_DATA).withMonitor();

    // Reset the purchase history when navigating to the order history (main page) page.
    // This allows for a new fetch of purchases in the event one could've been streamed while the customer was in a different page in PYO
    this.context.resetPurchaseHistory();    

    getOrderHistory()
      .then((response: any) => {
        const purchaseHistory = response.data.purchaseHistory;
        this.context.addPurchaseHistory(purchaseHistory);
        this.setState({
          view: (<OrderHistory orderHistoryRedirectionComponentRow={this.props.location.state && this.props.location.state.orderHistoryRedirectionComponentRow ? this.props.location.state.orderHistoryRedirectionComponentRow :<OrderHistoryRedirectionComponentRow isRedirected={false}></OrderHistoryRedirectionComponentRow>} 
                               purchaseHistory={purchaseHistory} getOrderHistory={getOrderHistory}/>)
        })
        attemptMetric.setSuccess();
        actionMetricsPublisher.publish(attemptMetric);
      })
      .catch(() => {
        this.setState({
          view: (<pui-text data-csa-c-content-id="order-history-page-error" data-csa-c-type="card" data-csa-c-slot-id="order-history">Error on order history</pui-text>),
        })
        attemptMetric.setFailure();
        actionMetricsPublisher.publish(attemptMetric);
      });

  }

  render() {
        return (this.state.view);
  }
}
OrderHistoryHOC.contextType = AppContext;

export default OrderHistoryHOC;