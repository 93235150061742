import React from 'react';

interface RowInput {
    label?: string,
    value?: string,
    fontWeight?: string,
    spacingTop?: string,
    spacingBottom?: string,
    loading?: boolean,
}

export default (props: RowInput) => {
    const {label, value, fontWeight, spacingTop, spacingBottom, loading} = props;
    const styling = {fontWeight, spacingTop, spacingBottom};

    if (loading) {
        return (<tr>
            <td>
                <div className="pui-loading-text-medium small-margin-top small-margin-bottom" style={{'margin': 'auto auto auto 0', 'width': '75%'}}/>
            </td>
            <td>
                <div className="pui-loading-text-medium small-margin-top small-margin-bottom" style={{'margin': 'auto 0 auto auto', 'width': '25%'}}/>
            </td>
        </tr>)
    }

    if (!value || !label) {
        return (<></>);
    }

    return (<tr>
        <td>
            <pui-text {...styling} input={`${label}:`} textAlign="left" />
        </td>
        <td>
            <pui-text {...styling} input={value} textAlign="right" />
        </td>
    </tr>)
}
