import React from 'react';

interface CategoryTabProps {
    idx: number,
    title: string,
    selected: boolean,
    onChange(idx: number): void;
}

export function CategoryTab({idx, title, selected, onChange}: CategoryTabProps) {
    const handleOnClick = () => {
        onChange(idx);
    }
    if (selected) {
        return (<pui-category-tab id={`order-history-tab-${title}`} data-csa-c-content-id={`order-history-tab-${title}`} data-csa-c-type="element" data-csa-c-action="selected"
                                  data-csa-c-slot-id="order-history" title={title} onClick={handleOnClick} selected={selected} />);
    } else {
        return (<pui-category-tab title={title} onClick={handleOnClick}/>);
    }
}