import React, { FunctionComponent, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';

interface ForwardLinkProps {
    url: string;
    onClick?: () => void;
}

const handleClickEvent = (event: SyntheticEvent, props: ForwardLinkProps) => {
    if (props.onClick) {
        event.preventDefault();
        props.onClick()
    }
}

export const ForwardTextLink: FunctionComponent<ForwardLinkProps> = (props) =>
    <Link to={props.url} style={{ textDecoration: 'none' }} onClick={(e) => handleClickEvent(e, props)}>
        <pui-stack direction='vertical'>
            {props.children}
        </pui-stack>
    </Link>

