import React, {Component} from 'react';

/**
 * This is something the BasePage is adding around our content.
 * It is how we get
 * | gutter | content | gutter |
 * Such that our cards are centered nice and pretty!
 * And they fill the page vertically!
 */
class PuiContainer extends Component {
    render() {
        return (
            <pui-section centerlane padding="medium" pageContainer width="auto" class="content-container">
                {this.props.children}
            </pui-section>
        );
    }
}

export default PuiContainer
