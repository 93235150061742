import React from 'react';
import ChargesRow from './ChargesRow';

export default class ChargesLoading extends React.Component {

    render() {
        return (
            <pui-stack>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <ChargesRow loading={true}/>
                        <ChargesRow loading={true}/>
                        <ChargesRow loading={true}/>
                        <ChargesRow loading={true}/>
                        <ChargesRow loading={true}/>
                    </tbody>
                </table>
            </pui-stack>
        )
    }
}
