import React from 'react';
import { Charge } from '../component';

function _generateChargeRow(label?: string, value?: string) {
    return (!label || !value ? <></> : <tr>
        <td>
            <pui-text input={label} />
        </td>
        <td>
            <pui-text input={value} />
        </td>
    </tr>)
}

export default (charges: Charge) => 
    (<>
        <tr><td><pui-text spacingTop='small' input={charges.itemLabel} /></td>
            <td><pui-text spacingTop='small' input={charges.item} /></td></tr>
        {_generateChargeRow(charges.shippingLabel, charges.shipping)}
        { charges.couponSaving &&  _generateChargeRow(charges.couponSavingLabel, charges.couponSaving)}
        {_generateChargeRow(charges.copayLabel, charges.copay)}
        {_generateChargeRow(charges.totalBeforeTaxLabel, charges.totalBeforeTax)}
        {_generateChargeRow(charges.salesTaxLabel, charges.salesTax)}
        <tr><td><pui-text fontWeight='bold' input={charges.grandTotalLabel} /></td>
            <td><pui-text fontWeight='bold' input={charges.grandTotal} /></td></tr>
    </>
    );
