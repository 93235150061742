import React, { useEffect, useRef } from "react";
import {CancellationReasonFilterOption} from '../component';

//original design from https://tiny.amazon.com/18leiwst3/codeamazpackPharblobmainsrc
interface ICancellationDropdownProps {
  selectedOption?: CancellationReasonFilterOption;
  onSelectionChange?: (this: void, optionKey: any) => void;
  label?: string;
  placeHolderLabel?: string;
}

export const CancellationDropdown: React.FC<ICancellationDropdownProps> = ({
  children,
  selectedOption,
  onSelectionChange,
  label,
  placeHolderLabel
}) => {
  const dropdownRef = useRef<HTMLElement>();

  useEffect(() => {
    const dropdownEl: any = dropdownRef.current;
    if (!dropdownEl) return;

    if (!!selectedOption) {
      dropdownEl.value = selectedOption.value;
      dropdownEl.selectedText = selectedOption.key;
    }

    dropdownEl.onSelect = (newValue: any) => {;
      if (!!selectedOption) {
        dropdownEl.value = selectedOption.value;
        dropdownEl.selectedText = selectedOption.key;
      } else {
        dropdownEl.reset();
      }
      onSelectionChange?.call(void 0, newValue);
    };
  }, [dropdownRef, selectedOption, onSelectionChange]);

  return (
    <pui-dropdown-two ref={dropdownRef} label={label} placeholder={placeHolderLabel}>
      {children}
    </pui-dropdown-two>
  );
};

interface ICancellationDropdownItemProps {
  key: string,
  filterOption: CancellationReasonFilterOption;
}

export const CancellationDropdownItem: React.FC<ICancellationDropdownItemProps> = ( {
  key,
  filterOption
} ) => {
  return <pui-dropdown-item-two id={key}  type="option" label={filterOption.key} value={filterOption.value}/>;
};