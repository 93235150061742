import React from 'react';

import { I18NContext } from '../I18NContext';
import PUILink from './common/PUILink';
import ContactCustomerCare from './common/ContactCustomerCare';

interface HelpProps {
    orderId: string;
    viewInvoice: string;
    contactCustomerCare: string;
}

class Help extends React.Component<HelpProps, {}> {

    render() {
        let children;
        if (this.props.children) {
            children = (<pui-stack-item>{this.props.children}
                <pui-divider /></pui-stack-item>);
        }

        return (
            <pui-stack direction='vertical'>
                {children}
                <PUILink csaId="invoice" text={this._generateHelpLinkContent(this.props.viewInvoice)} href={`/invoice/${this.props.orderId}`} replace={true} align="center"/>
                <pui-divider></pui-divider>
                <ContactCustomerCare contactCustomerCare={this.props.contactCustomerCare}/>
            </pui-stack>
        );
    }

    _generateHelpLinkContent(linkName: string) {
        return `<pui-stack direction='vertical'>
            <pui-stack-item-button>
                <pui-text input='${linkName}' textSize='medium' />
            </pui-stack-item-button>
        </pui-stack>`
    }
}

Help.contextType = I18NContext;

export default Help;
