import React from 'react';

import './OrderSummary.scss';
import '../tracker/TrackerSteps.scss'
import { DeliveryEvent } from '../component';


interface OrderSummaryProps {
    events: DeliveryEvent[],
    lastStepProgress: number;
}

export default (props: OrderSummaryProps) => {
    const eventView = props.events.map((event, i, arr) => {
        let progressMarker = (<div className={"step-completed-icon"}/>)
        let className = 'order-progress-bar progress-100'
        if (i === arr.length - 1) {
            className = '';
            if (props.lastStepProgress !== 100) {
                progressMarker = (<div className={"step-last-icon"}/>);
            }
        } else if (i === arr.length - 2) {
            className = `order-progress-bar progress-${props.lastStepProgress}`
        }

        const possibleHeadling = [event.note, event.subline, event.headline]
            .filter(text => !!text);

        return (
            <pui-stack direction="horizontal" key={i + '-ordersummary'} mainaxisarrangement="start" secondaryaxisarrangement="start">
                <pui-stack-item  class={className} >
                    {progressMarker}
                </pui-stack-item>
                <pui-stack-item spacingLeft="med-small" overflow="true">
                    <pui-stack direction="vertical">
                        <pui-stack-item overflow="true">
                            <pui-text input={possibleHeadling.pop()} fontWeight="bold" />
                        </pui-stack-item>
                        <pui-stack-item overflow="true">
                            <pui-text input={possibleHeadling.pop()} fontWeight="normal" textSize="small" />
                        </pui-stack-item>
                    </pui-stack>
                </pui-stack-item>
            </pui-stack>
        )
    });

    return (<pui-stack flex={"true"} flowDirection={"vertical"}>{eventView}</pui-stack>);
}