// <SERVER CONTENT>
// These are all of our static strings.
// The tricker/replacement ones will come with the gql api as they rely on that data anyway
// And we'll need to deploy Panther's stuff and Panther only has Java support atm.
// This is a dump of Panther's stuff for the detected Locale.
// </SERVER CONTENT>
class I18N {
    static DEFAULT_LOCALE = "en-US";
    static LOCALES : any = {
        'en-US': {
            CHARGE_TAX_MESSAGE: "(Includes taxes and shipping)"
        },
        version: 1
    };
    strings: any;
    locale: string;
    constructor(overrideLocale: string | null = null, private defaultLocale: string = I18N.DEFAULT_LOCALE) {
        //https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/language
        this.defaultLocale = defaultLocale || I18N.DEFAULT_LOCALE;
        this.locale = overrideLocale || navigator.language || this.defaultLocale || I18N.DEFAULT_LOCALE;
        this.fixupUsingLocale();
        this.configure();
    }

    static add(locale: string, strings: any) {
        I18N.LOCALES[locale] = strings;
        I18N.LOCALES.version++;
    }

    fixupUsingLocale() {
        if(!I18N.LOCALES.hasOwnProperty(this.locale)) {
            console.warn(`Locale not found: ${this.locale}.  Attempting fix.`)
            // might be all lower variant
            const parts = this.locale.split("-");
            if(parts.length === 2) {
                this.locale = parts[0] + "-" + parts[1].toUpperCase();
            } else {
                console.warn(`Invalid locale! Using default locale: ${this.locale}`)
                this.locale = this.defaultLocale;

            }
        }

        if(!I18N.LOCALES.hasOwnProperty(this.locale)) {
            console.warn(`Using default locale: ${this.defaultLocale}`)
            this.locale = this.defaultLocale;
        }
    }

    configure() {
        // load the proper map from locales
        this.strings = I18N.LOCALES[this.locale];
    }
    
    get(id: string): string {
        if(null === id || undefined === id) {
            console.error("Requested null or undefined string!");
            return "";
        }

        const upper = id.toUpperCase();

        if(this.strings.hasOwnProperty(upper)) {
          return this.strings[upper];
        }

        if(this.strings.hasOwnProperty(upper.toLowerCase()))
        console.error(`Missing ID ${upper} given as ${id}`);
        return "";
    }
}

export default I18N;

  