import React from 'react';
import InvoiceAttributeRowLoading from './InvoiceAttributeRowLoading';
import InvoiceChargesLoading from './InvoiceChargesLoading';

class InvoiceItemSummaryLoading extends React.Component {
    render() {
        return (
            <pui-stack>
                <div className="pui-loading-heading-large-dark small-margin-bottom" style={{ width: '50%'}}/>
                <div className="pui-loading-text-medium small-margin-bottom"/>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <InvoiceAttributeRowLoading/>
                        <InvoiceAttributeRowLoading/>
                        <InvoiceAttributeRowLoading/>
                        <InvoiceAttributeRowLoading/>
                        <InvoiceAttributeRowLoading/>
                        <InvoiceAttributeRowLoading/>
                        <InvoiceAttributeRowLoading/>
                        <InvoiceAttributeRowLoading/>
                        <InvoiceChargesLoading/>
                    </tbody>
                </table>
            </pui-stack>
        );
    }
}

export default InvoiceItemSummaryLoading;