import React from 'react';
import HeavyDivider from '../common/HeavyDivider';
import { I18NContext } from '../../I18NContext';
import MedicationCardLoading from '../common/MedicationCardLoading';
import ChargesLoading from '../ChargesLoading';
import { BreadCrumbLoading } from '../common/BreadCrumb';
import RefundLoading from './RefundLoading';

class OrderDetailsLoading extends React.Component {
    render() {
        return (
            <pui-stack>
                <BreadCrumbLoading/>
                <div className="pui-loading-heading-extra-large-dark small-margin-top" style={{width: '75%'}}/>
                <div className="pui-loading-text-medium small-margin-top small-margin-bottom"/>
                <HeavyDivider />
                <div className="pui-loading-heading-medium-dark small-margin-bottom"/>
                <MedicationCardLoading/>
                <pui-divider spacingTop='medium' spacingBottom='medium' />
                <ChargesLoading/>
                <pui-divider spacingTop='medium' spacingBottom='medium' />
                <RefundLoading/>
                <HeavyDivider />
                <div className="pui-loading-heading-large-dark medium-margin-top medium-margin-bottom" style={{width: '50%'}}/>
                <div className="pui-loading-text-medium small-margin-bottom"/>
                <div className="pui-loading-text-medium large-margin-bottom"/>
                <HeavyDivider />
                <div className="pui-loading-heading-medium-dark"/>
                <pui-stack direction='vertical'>
                    <pui-stack-item spacingTop='medium'>
                        <pui-stack-item-button>
                            <div className="pui-loading-text-medium"/>
                        </pui-stack-item-button>
                    </pui-stack-item>
                </pui-stack>
            </pui-stack>
        )
    }
}
OrderDetailsLoading.contextType = I18NContext;

export default OrderDetailsLoading;
