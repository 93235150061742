import React, {useContext, useRef, useState} from 'react';
import { ItemGroupSummary, PurchaseHistory } from "../component";
import { ListDelimiter } from '../common/ListDelimiter';
import ErrorBoundary from '../../ErrorBoundary';
import { AppContext } from '../../AppContext';

import appMetrics from '../../common/MetricsService';
import MetricsConstants from '../../common/MetricsConstants';
import * as KatalMetrics from '@amzn/katal-metrics';

interface OlderOrdersProps {
    getOrderHistory: (fromDate: Date) => any;
    _processItemGroups: (itemGroupSummary: ItemGroupSummary, previous: boolean) => any;
    purchaseHistory: PurchaseHistory;
    listDelimiter: JSX.Element;
}

function OlderOrdersComponent(props: OlderOrdersProps) {
    const [showFailureMessage, setShowFailureMessage] = useState(false);
    const [fromDate, setFromDate] = useState(new Date());
    const [olderProcessedItemGroup, setOlderProcessedItemGroup]: any = useState([]);
    const loadMoreRef = useRef<any>();
    const appContext = useContext(AppContext);
    const DAYS_SPAN = 90;
    const LAST_DATE_OF_ORDERS = new Date('2020-01-01'); // Arbitrary date set given 2020 is the first year pharmacy was available.

    const _getMoreOrders = (fromDate: Date) => {
        return props.getOrderHistory(fromDate)
            .then((response: any) => {
                return response.data.purchaseHistory;
            })
            .catch((error: Error) => {
                return error;
            });
    }

    const _attemptLoadMoreOrders = async () => {
        const actionMetricsPublisher = appMetrics.getMetricsPublisherForMethod("OlderOrdersHistory");
        const attemptMetric = new KatalMetrics.Metric.TimedAttempt(MetricsConstants.METRIC_LOAD_PAGE_DATA).withMonitor();
    
        if(_shouldHideLoadMoreButton(fromDate)) {
            _hideLoadMoreButton();
            attemptMetric.setSuccess();
            actionMetricsPublisher.publish(attemptMetric);
        } else {
            const queryDate = _getFromDate();
            setFromDate(queryDate);
            const moreOrders = await _getMoreOrders(queryDate);

            if(moreOrders instanceof Error) {
                _setStateForFailureToLoadPastOrders();
                attemptMetric.setFailure();
                actionMetricsPublisher.publish(attemptMetric);
            } else {
                if(moreOrders.upcoming.length > 0 || moreOrders.past.length > 0) {
                    appContext.addPurchaseHistory(moreOrders);
                    _updateOlderPurchaseHistory(moreOrders);
                    _hideLoading();
                    attemptMetric.setSuccess();
                    actionMetricsPublisher.publish(attemptMetric);
                } else {
                    _attemptLoadMoreOrders();
                }
            }
        }
    }

    // Arbitrary date (LAST_DATE_OF_ORDERS) for when to remove the 'see more' button as
    // there is no way to determine how many orders a customer has until it's queried for
    const _shouldHideLoadMoreButton = (fromDate: Date) => {
        return fromDate <= LAST_DATE_OF_ORDERS;
    }

    const _updateOlderPurchaseHistory = (purchaseHistory: PurchaseHistory) => {
        const processedPastItemGroups: any[] = purchaseHistory.past.map((summary) => props._processItemGroups(summary, true));
        const processedUpcomingItemGroups: any[] = purchaseHistory.upcoming.map((summary) => props._processItemGroups(summary, true));
        setOlderProcessedItemGroup([...olderProcessedItemGroup, processedPastItemGroups, processedUpcomingItemGroups]);
    }

    const _getFromDate = () => {
        var newDate = fromDate;
        newDate.setDate(fromDate.getDate() - DAYS_SPAN);
        if(newDate < LAST_DATE_OF_ORDERS) {
            return LAST_DATE_OF_ORDERS;
        } else {
            return newDate;
        }
    }

    const _setStateForFailureToLoadPastOrders = () => {
        _hideLoadMoreButton();
        setShowFailureMessage(true);
    }

    const _hideLoadMoreButton = () => {
        if(loadMoreRef.current) {
            loadMoreRef.current.hide();
        }
    }

    const _showLoading = () => {
        if(loadMoreRef.current) {
            loadMoreRef.current.displaySpinner();
            loadMoreRef.current.disable();
            loadMoreRef.current.displayTransitionLabel();
        }
    }

    const _hideLoading = () => {
        if(loadMoreRef.current) {
            loadMoreRef.current.hideSpinner();
            loadMoreRef.current.enable();
        }
    }

    return (<>
                {olderProcessedItemGroup.length > 0 && <>
                    <pui-heading
                        input={props.purchaseHistory.labels.ordersOlderThan90Days}
                        spacingLeft="small"
                        textSize="large"
                        spacingBottom="small"
                        style={{display: "grid"}}/>
                        <ListDelimiter delimiter={props.listDelimiter}>
                            {olderProcessedItemGroup}
                        </ListDelimiter>
                        </>
                }
                {showFailureMessage &&
                    <pui-box theme="alert" style={{width: "auto"}} spacingTop="small">
                        <pui-stack direction="horizontal">
                            <pui-stack-item>
                                <pui-icon imgClass="status-warning-icon" spacingRight="small"/>
                            </pui-stack-item>
                            <pui-stack-item overflow="true">
                                <pui-text input={props.purchaseHistory.labels.seeMoreOrdersFailure}/>
                            </pui-stack-item>
                        </pui-stack>
                    </pui-box>
                }
                <ErrorBoundary>
                    <pui-button
                        id="button-to-see-more-orders"
                        data-csa-c-type="button"
                        data-csa-c-action="load"
                        data-csa-c-content-id="button-to-see-more-orders"
                        data-csa-c-slot-id="past-orders"
                        theme="secondary"
                        onClick={() => {
                            _showLoading();
                            _attemptLoadMoreOrders();
                        }}
                        label={props.purchaseHistory.labels.seeMoreOrders}
                        transitionLabel={props.purchaseHistory.labels.seeMoreOrdersLoading}
                        textColor="black-color"
                        textSize="large"
                        spacingTop="small"
                        ref={loadMoreRef}
                        />
                </ErrorBoundary>
    </>);
}

export default OlderOrdersComponent;